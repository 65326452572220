<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    version="1.1"
    :class="{ 'techno-icon': active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path d="M12.552,43.894 C12.74,43.988 12.908,44.082 13.075,44.175 C14.834,45.154 16.352,46 31,46 C45.648,46 47.166,45.154 48.925,44.175 C49.092,44.082 49.26,43.988 49.448,43.894 C49.941,43.646 50.141,43.046 49.894,42.552 C49.646,42.058 49.048,41.858 48.551,42.106 C48.336,42.214 48.143,42.321 47.95,42.428 C46.5,43.236 45.129,44 31,44 C16.871,44 15.5,43.236 14.049,42.428 C13.857,42.321 13.663,42.214 13.448,42.106 C12.951,41.858 12.352,42.059 12.105,42.552 C11.858,43.045 12.059,43.646 12.552,43.894 L12.552,43.894 Z" id="Shape"></path>
    <path d="M6,47 C6,49.892 7.702,52.469 10.553,53.895 C10.739,53.988 10.942,54.099 11.171,54.223 C13.423,55.449 18.109,58 31,58 C43.891,58 48.577,55.449 50.829,54.223 C51.058,54.098 51.262,53.988 51.447,53.895 C54.298,52.469 56,49.892 56,47 C56,44.349 57.814,41.583 57.833,41.556 C57.941,41.392 58,41.199 58,41.002 C58,39.7 57.161,38.6 56,38.186 L56,35 C56,24.071 50.902,15.767 42,12.079 L42,12 C42,10.376 40.703,9.049 39.091,9.001 L33.856,8.128 C33.48,6.901 32.349,6 31,6 C29.651,6 28.52,6.901 28.144,8.129 L22.909,9.002 C21.297,9.049 20,10.376 20,12 L20,12.079 C11.099,15.767 6,24.07 6,35 L6,38.186 C4.839,38.6 4,39.7 4,41.002 C4,41.2 4.059,41.393 4.168,41.557 C4.187,41.584 6,44.334 6,47 L6,47 Z M8,35 C8,25.095 12.25,17.789 20,14.25 L20,23 C20,23.552 20.447,24 21,24 C21.553,24 22,23.552 22,23 L22,12 C22,11.449 22.448,11 23,11 C23.055,11 23.11,10.996 23.164,10.986 L28,10.18 L28,25 C28,25.552 28.447,26 29,26 C29.553,26 30,25.552 30,25 L30,9 C30,8.449 30.448,8 31,8 C31.552,8 32,8.449 32,9 L32,25 C32,25.552 32.447,26 33,26 C33.553,26 34,25.552 34,25 L34,10.18 L38.836,10.986 C38.89,10.996 38.945,11 39,11 C39.552,11 40,11.449 40,12 L40,12.735 C40,12.757 39.999,12.778 40,12.8 L40,23 C40,23.552 40.447,24 41,24 C41.553,24 42,23.552 42,23 L42,14.249 C49.75,17.788 54,25.094 54,35 L54,39.002 C54,39.554 54.447,40.002 55,40.002 C55.469,40.002 55.863,40.326 55.971,40.762 C55.434,41.654 54,44.289 54,47 C54,50.052 51.589,51.587 50.553,52.105 C50.349,52.207 50.125,52.329 49.873,52.466 C47.766,53.613 43.382,56 31,56 C18.618,56 14.234,53.613 12.127,52.466 C11.875,52.329 11.651,52.207 11.447,52.105 C10.411,51.587 8,50.052 8,47 C8,44.289 6.566,41.654 6.029,40.762 C6.136,40.326 6.531,40.002 7,40.002 C7.553,40.002 8,39.554 8,39.002 C8,39.002 8,39.002 8,39.001 C8,39 8,39.001 8,39 L8,35 L8,35 Z" id="Shape"></path>
    <!-- <path d="M28.875 13.7184H29.725V12.8684V3.55263C29.725 3.30304 29.8085 3.13767 29.9081 3.0373C30.0063 2.93835 30.1721 2.85 30.4375 2.85H33.5625C33.8495 2.85 34.0104 2.94197 34.0993 3.03294C34.1916 3.12722 34.275 3.29096 34.275 3.55263V12.8684V13.7184H35.125H38.25H39.1V12.8684V6.50609C39.6846 6.86928 40.4218 7.40729 41.1593 8.15797C42.781 9.80888 44.4313 12.5128 44.4313 16.75V17.4477L45.1028 17.5812C45.1041 17.5815 45.1057 17.582 45.1077 17.5825C45.1118 17.5837 45.1173 17.5853 45.1241 17.5874C45.1532 17.5964 45.2051 17.6143 45.2729 17.6452C45.4083 17.7069 45.6039 17.819 45.8083 18.0137C46.1144 18.3053 46.4922 18.832 46.6748 19.7816H17.2856C17.4307 18.8318 17.8098 18.3116 18.1226 18.0235C18.3325 17.8303 18.5398 17.7166 18.6887 17.6525C18.7629 17.6205 18.821 17.6016 18.8552 17.5917C18.8696 17.5875 18.8796 17.5849 18.8844 17.5838L19.5688 17.4568V16.75C19.5688 12.5128 21.219 9.80888 22.8407 8.15797C23.5782 7.40729 24.3154 6.86928 24.9 6.50609V12.8684V13.7184H25.75H28.875ZM7.85 54.7895C7.85 51.8562 10.2637 48.9701 14.6985 46.7667C19.0812 44.5892 25.1962 43.2184 32 43.2184C38.8038 43.2184 44.9188 44.5892 49.3015 46.7667C53.7363 48.9701 56.15 51.8562 56.15 54.7895V60.15H7.85V54.7895ZM23.7603 31.9169C21.7788 29.9479 20.5858 27.3433 20.3815 24.5868H43.6185C43.4142 27.3433 42.2213 29.9479 40.2397 31.9169C38.0553 34.0875 35.0915 35.3079 32 35.3079C28.9085 35.3079 25.9447 34.0875 23.7603 31.9169Z"/> -->
    <!-- <path d="M29.688 2.182c-.74.322-.722.193-.781 5.707l-.054 4.964H25.76l-.028-3.92-.028-3.919-.879.548c-2.77 1.728-4.442 3.794-5.411 6.685-.37 1.106-.64 2.662-.64 3.701l-.001.748-.536.255c-.943.448-1.505 1.29-1.764 2.641-.084.44-.153.844-.153.897 0 .059 6.306.098 15.68.098 8.624 0 15.68-.021 15.68-.047 0-.255-.368-1.685-.526-2.041-.269-.609-.969-1.353-1.498-1.591l-.429-.194-.001-.757c0-1.054-.269-2.603-.648-3.732-.911-2.719-2.597-4.859-5.105-6.481l-1.126-.728-.054 3.919-.053 3.918h-3.093l-.054-4.96c-.058-5.388-.044-5.275-.673-5.658-.306-.187-.544-.207-2.367-.204-1.482.003-2.117.044-2.365.151m3.724 6.965.028 5.413h6.507L40 11.413l.053-3.146.573.586c.316.323.837.986 1.158 1.474 1.138 1.726 1.665 3.565 1.798 6.281.051 1.044.115 1.529.206 1.564.072.028.358.085.635.126.289.044.624.182.787.326l.283.249-13.49.004-13.49.003.226-.226c.142-.142.479-.272.904-.348l.677-.121.12-1.812c.131-1.974.371-3.17.882-4.402.413-.997 1.316-2.37 2.049-3.118l.576-.586.053 3.146.053 3.147h6.507l.028-5.413.027-5.414h2.77l.027 5.414M19.52 24.306c0 .286.072.93.159 1.432.94 5.391 5.096 9.452 10.507 10.268 5.943.896 11.738-2.644 13.645-8.335.357-1.066.649-2.58.649-3.365v-.519H19.52v.519m23.086 1.175c.087.142-.322 1.645-.652 2.394-.977 2.219-2.55 3.986-4.578 5.141-5.628 3.207-12.846.772-15.351-5.181-.332-.787-.715-2.218-.631-2.354.033-.054 4.597-.094 10.606-.094 6.009 0 10.573.04 10.606.094M29.653 42.42c-6.861.319-13.544 2.178-17.611 4.901-2.261 1.513-3.572 2.929-4.414 4.767-.547 1.194-.588 1.57-.588 5.411v3.514h49.92v-3.514c0-2.107-.046-3.742-.116-4.08-.168-.822-.953-2.372-1.637-3.232-4.036-5.077-14.565-8.278-25.554-7.767m7.183 1.897c3.214.345 6.428 1.026 8.897 1.883 1.688.586 4.102 1.757 5.409 2.625 1.324.878 2.75 2.281 3.337 3.282.721 1.229.774 1.544.774 4.552v2.648H8.747v-2.656c0-3.105.075-3.488.954-4.825 1.755-2.672 6.061-5.109 11.395-6.448 2.573-.646 5.225-1.037 8.557-1.262 1.193-.08 5.753.047 7.183.201" fill-rule="evenodd" fill="#000"/> -->
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "64px",
    },
    height: {
      type: String,
      default: "64px",
    },
  },
};
</script>

<style scoped>
.techno-icon {
  fill: #009f4d !important;
}
</style>