<template>
    <div style="display: contents">
        <v-row style="margin-top:20px;">
            <v-col cols="2" style="margin-left:20px;">
                 <v-icon>mdi-translate</v-icon>
            </v-col>
           <v-col cols="8">
               <v-select
                    @change="changeLanguage"
                    v-model="locale"
                    
                    :items="allLanguages"
                    
                    item-text="text"
                    item-value="value"

                    dense
                />
           </v-col>
            
        </v-row>
    </div>
</template>
<script>
import { languageList } from '@/utils/languages'
import { getLanguageWithoutRegion } from '@/utils/utils'

export default {
    data() {
        return {
            locale: this.$i18n.locale,
        }
    },
    methods: {
        setLanguage(language) {
            this.locale = language
            this.$root.$i18n.locale = language;
            localStorage.setItem("language", language);

            // Vuetify use BCP 47 norms. fr-CA en-US... 
            this.$root.$vuetify.lang.current = getLanguageWithoutRegion(language);
        },
        changeLanguage() {
            this.setLanguage(this.locale)
            this.$emit('languageChanged');
        },
    },
    computed: {
        allLanguages() {
            return languageList();
        }
    }
}
</script>