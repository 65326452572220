<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    version="1.1"
    :class="{ 'techno-icon' : active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <circle id="Oval" cx="32" cy="8" r="2"></circle>
    <path
      d="M14,62.002 L50,62.002 C52.206,62.002 54,60.208 54,58.002 L54,16.002 C54,13.798 52.206,12.002 50,12 L44,12 L44,11 C44,10.448 43.552,10 43,10 L38,10 L38,8 C38,4.691 35.309,2 32,2 C28.691,2 26,4.691 26,8 L26,10 L21,10 C20.448,10 20,10.448 20,11 L20,12 L13.999,12 C11.794,12.002 10,13.798 10,16.002 L10,58.002 C10,60.208 11.794,62.002 14,62.002 L14,62.002 Z M21,20 L43,20 C43.552,20 44,19.552 44,19 L44,18 L48,18 L48,56 L16,56 L16,18 L20,18 L20,19 C20,19.552 20.448,20 21,20 L21,20 Z M22,12 L27,12 C27.552,12 28,11.552 28,11 L28,8 C28,5.794 29.794,4 32,4 C34.206,4 36,5.794 36,8 L36,11 C36,11.552 36.448,12 37,12 L42,12 L42,18 L22,18 L22,12 L22,12 Z M12,16.002 C12,14.899 12.898,14.002 14,14 L20,14 L20,16 L15,16 C14.448,16 14,16.448 14,17 L14,57 C14,57.552 14.448,58 15,58 L49,58 C49.552,58 50,57.552 50,57 L50,17 C50,16.448 49.552,16 49,16 L44,16 L44,14 L49.999,14 C51.102,14.001 52,14.899 52,16.002 L52,58.002 C52,59.105 51.103,60.002 50,60.002 L14,60.002 C12.897,60.002 12,59.105 12,58.002 L12,16.002 L12,16.002 Z"
      id="Shape"
    ></path>
    <path
      d="M25,30.002 L43,30.002 C43.552,30.002 44,29.554 44,29.002 C44,28.45 43.552,28.002 43,28.002 L25,28.002 C24.448,28.002 24,28.45 24,29.002 C24,29.554 24.448,30.002 25,30.002 L25,30.002 Z"
      id="Shape"
    ></path>
    <path
      d="M25,36.002 L43,36.002 C43.552,36.002 44,35.554 44,35.002 C44,34.45 43.552,34.002 43,34.002 L25,34.002 C24.448,34.002 24,34.45 24,35.002 C24,35.554 24.448,36.002 25,36.002 L25,36.002 Z"
      id="Shape"
    ></path>
    <path
      d="M25,42.002 L43,42.002 C43.552,42.002 44,41.554 44,41.002 C44,40.45 43.552,40.002 43,40.002 L25,40.002 C24.448,40.002 24,40.45 24,41.002 C24,41.554 24.448,42.002 25,42.002 L25,42.002 Z"
      id="Shape"
    ></path>
    <path
      d="M25,48.002 L43,48.002 C43.552,48.002 44,47.554 44,47.002 C44,46.45 43.552,46.002 43,46.002 L25,46.002 C24.448,46.002 24,46.45 24,47.002 C24,47.554 24.448,48.002 25,48.002 L25,48.002 Z"
      id="Shape"
    ></path>
    <path
      d="M20.29,46.3 C20.11,46.48 20,46.74 20,47 C20,47.27 20.11,47.52 20.29,47.71 C20.48,47.9 20.74,48 21,48 C21.26,48 21.52,47.9 21.71,47.71 C21.89,47.52 22,47.26 22,47 C22,46.74 21.89,46.48 21.71,46.3 C21.34,45.92 20.66,45.92 20.29,46.3 L20.29,46.3 Z"
      id="Shape"
    ></path>
    <path
      d="M20.29,40.29 C20.11,40.48 20,40.74 20,41 C20,41.26 20.11,41.52 20.29,41.71 C20.48,41.89 20.74,42 21,42 C21.26,42 21.52,41.89 21.71,41.71 C21.89,41.52 22,41.26 22,41 C22,40.74 21.89,40.48 21.71,40.29 C21.33,39.92 20.67,39.92 20.29,40.29 L20.29,40.29 Z"
      id="Shape"
    ></path>
    <path
      d="M20.29,34.29 C20.11,34.48 20,34.74 20,35 C20,35.26 20.11,35.52 20.29,35.71 C20.48,35.89 20.74,36 21,36 C21.26,36 21.52,35.89 21.71,35.71 C21.89,35.52 22,35.26 22,35 C22,34.74 21.89,34.48 21.71,34.29 C21.33,33.92 20.67,33.92 20.29,34.29 L20.29,34.29 Z"
      id="Shape"
    ></path>
    <path
      d="M20.29,29.71 C20.48,29.89 20.74,30 21,30 C21.26,30 21.52,29.89 21.71,29.71 C21.89,29.52 22,29.26 22,29 C22,28.74 21.89,28.48 21.71,28.29 C21.33,27.92 20.67,27.92 20.29,28.29 C20.11,28.48 20,28.74 20,29 C20,29.26 20.11,29.52 20.29,29.71 L20.29,29.71 Z"
      id="Shape"
    ></path>
  </svg>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '64px'
        },
        height: {
            type: String,
            default: '64px'
        }
    }
}
</script>

<style scoped>
.techno-icon {
  fill: #009f4d !important;
}
</style>