
export default {
    isMobile: null,
    drawer: false,
    breadcrumbs: [],
    isInitializingData: false,
    isDataInitialized: false,
    currentUser: null,
    badgeNumber : null,
    userFunctions: null,
    userRoles: null,
    countries: null,
    errorMessage: null,
    showToast: false,
    toastMessage: null,
    toastButtonColor: "red",
    toastTimeout: null,
    availableLanguages: [],
    socket: null
}