<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 500 500"
    version="1.1"
    :class="{ 'techno-icon': active, 'inactive': !active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
  <g>
  <path d="M288.97,407.24c-6.57-8.03-16.59-13.17-27.79-13.17H35.82C16.07,394.07,0,410.04,0,429.66c0,0.05,0,0.09,0,0.13
    c0,0.05,0,0.1,0,0.15c0,19.62,16.07,35.58,35.81,35.58c1.49,0,2.96-0.1,4.41-0.28h66.38c1.45,0.18,2.92,0.28,4.41,0.28
    s2.96-0.1,4.41-0.28h66.38c1.45,0.18,2.92,0.28,4.41,0.28s2.96-0.1,4.41-0.28h66.38c1.45,0.18,2.92,0.28,4.41,0.28
    c19.75,0,35.81-15.96,35.81-35.58C297.24,421.32,294.13,413.4,288.97,407.24z M39.66,456.01h-3.84c-14.57,0-26.44-11.74-26.52-26.2
    c0.08-14.46,11.94-26.2,26.51-26.2c14.62,0,26.51,11.82,26.51,26.34C62.32,443.18,52.46,454.15,39.66,456.01z M60.17,456.01
    c7.04-6.5,11.46-15.78,11.46-26.06c0-10.59-4.69-20.11-12.1-26.64h27.78c-7.42,6.52-12.1,16.04-12.1,26.64
    c0,10.28,4.42,19.56,11.46,26.06H60.17z M114.86,456.01h-7.69c-12.8-1.86-22.66-12.83-22.66-26.06c0-14.52,11.89-26.34,26.51-26.34
    s26.51,11.82,26.51,26.34C137.53,443.18,127.66,454.15,114.86,456.01z M135.37,456.01c7.04-6.5,11.46-15.78,11.46-26.06
    c0-10.59-4.69-20.11-12.1-26.64h27.78c-7.42,6.52-12.1,16.04-12.1,26.64c0,10.28,4.42,19.56,11.46,26.06H135.37z M190.07,456.01
    h-7.69c-12.8-1.86-22.66-12.83-22.66-26.06c0-14.52,11.89-26.34,26.51-26.34s26.51,11.82,26.51,26.34
    C212.73,443.18,202.87,454.15,190.07,456.01z M210.57,456.01c7.04-6.5,11.46-15.78,11.46-26.06c0-10.59-4.69-20.11-12.1-26.64
    h27.78c-7.42,6.52-12.1,16.04-12.1,26.64c0,10.28,4.42,19.56,11.46,26.06H210.57z M261.18,456.01h-3.6
    c-12.8-1.86-22.66-12.83-22.66-26.06c0-14.52,11.89-26.34,26.51-26.34c8.18,0,15.51,3.71,20.38,9.52
    c3.68,4.52,5.89,10.28,5.89,16.54C287.7,444.19,275.8,456.01,261.18,456.01z"/>
</g>
<g>
  <path d="M362.01,459.62c-0.21-0.34-0.56-0.58-0.95-0.67c-1.16-0.26-2.74-0.66-4.65-1.24
    c-10.77-3.26-31.87-12.24-45.36-34.8c-12.47-20.86-11.61-41.57-9.75-52.81c0.3-1.81,0.63-3.39,0.93-4.67
    c0.09-0.39,0.02-0.8-0.19-1.14c-0.21-0.34-0.55-0.58-0.95-0.68l-6.1-1.42c-0.82-0.19-1.63,0.31-1.83,1.13
    c-2.43,10.3-6.34,37.18,9.88,64.31c17.76,29.71,47.31,38.38,55.94,40.33c0.11,0.03,0.23,0.04,0.34,0.04c0.69,0,1.32-0.48,1.48-1.18
    l1.39-6.07C362.3,460.37,362.23,459.96,362.01,459.62z"/>
</g>
<path d="M225.09,309.42h-40.66c-0.84,0-1.52,0.68-1.52,1.51v6.02c0,0.83,0.68,1.51,1.52,1.51h40.66
  c0.84,0,1.52-0.68,1.52-1.51v-6.02C226.62,310.1,225.93,309.42,225.09,309.42z"/>
<path d="M225.09,329.12h-40.66c-0.84,0-1.52,0.68-1.52,1.51v6.01c0,0.83,0.68,1.51,1.52,1.51h40.66
  c0.84,0,1.52-0.68,1.52-1.51v-6.01C226.62,329.8,225.93,329.12,225.09,329.12z"/>
<path d="M225.09,348.82h-40.66c-0.84,0-1.52,0.68-1.52,1.51v6.01c0,0.83,0.68,1.51,1.52,1.51h40.66
  c0.84,0,1.52-0.68,1.52-1.51v-6.01C226.62,349.49,225.93,348.82,225.09,348.82z"/>
<g>
  <path d="M410.96,378.49c1.67-0.12,3.22-0.24,4.68-0.35c3.69-0.3,6.73-0.59,9.3-0.87c4.26-0.47,7.24-0.91,9.67-1.31
    c7.18-1.19,13.51-2.64,19.82-4.52c-8.47-0.01-20.96-0.02-29.5-0.02c-0.19,0-0.38,0-0.57,0c-2.83,2.01-5.76,3.72-8.74,5.13
    C414.1,377.27,412.53,377.92,410.96,378.49z"/>
  <path d="M388.44,264.17c0.01,36.58,0.02,73.15,0.03,109.73c6.22-0.26,12.14-0.74,17.62-2.37
    c3.24-0.96,6.43-2.36,9.55-4.17l-0.03-195.12l0-68.44c-0.35,0.02-0.69,0.06-1.05,0.06h0l-25.05,0.14c0,0-0.1,0-0.11,0
    c-0.33,0-0.66-0.02-0.98-0.03l0.01,70.05c0,0.05,0,0.11-0.01,0.16L388.44,264.17z"/>
  <path d="M371.08,61.26L91.56,228.29l39.39,16.28L376.01,98.13c-3.06-3.31-4.76-7.56-4.79-12.09L371.08,61.26z"/>
  <path d="M411.07,33.13l-0.11-18.42c-0.02-3.01-2.5-5.47-5.53-5.47h-0.04l-7.81,0.04c-1.47,0.01-2.86,0.59-3.9,1.63
    c-1.04,1.05-1.61,2.43-1.6,3.89l0.11,18.42L411.07,33.13z"/>
  <path d="M121.05,250.48l-39.39-16.28l-0.19-0.08H66.14H52.24c-14.62,0-26.51,11.82-26.51,26.34v83.6
    c0,14.38,11.78,26.18,26.27,26.31l1.49,0.03l197.73,0v-66.12l-119.9-49.55L121.05,250.48z"/>
  <path d="M424.71,397.07c-0.82,1.33-1.79,2.57-2.87,3.7c-0.42,0.44-0.86,0.86-1.33,1.27l4.45,0l20.18,0l-20.18-4.9
    L424.71,397.07z"/>
  <path d="M380.46,75.4l0.06,10.59c0.01,2.36,0.95,4.57,2.64,6.23c0.36,0.35,0.75,0.66,1.15,0.95
    c0.34,0.24,0.69,0.44,1.05,0.63c0.18,0.09,0.36,0.17,0.55,0.24c0.17,0.07,0.34,0.15,0.52,0.21c0.25,0.09,0.51,0.17,0.77,0.23
    c0.09,0.02,0.19,0.04,0.28,0.07c0.31,0.07,0.62,0.12,0.93,0.16c0.34,0.04,0.68,0.07,1.02,0.07l9.65-0.05l15.41-0.09
    c0.37,0,0.74-0.03,1.1-0.08c0,0,0,0,0,0c0,0,0,0,0,0c1.6-0.21,3.06-0.84,4.28-1.78c0.04-0.03,0.08-0.06,0.12-0.09
    c0.15-0.12,0.3-0.25,0.44-0.38c0.06-0.05,0.12-0.11,0.18-0.16c0.13-0.12,0.25-0.25,0.37-0.38c0.07-0.07,0.14-0.15,0.2-0.22
    c0.11-0.12,0.21-0.25,0.31-0.38c0.07-0.09,0.14-0.18,0.21-0.28c0.09-0.12,0.17-0.25,0.25-0.38c0.07-0.11,0.13-0.22,0.2-0.33
    c0.07-0.12,0.14-0.25,0.21-0.38c0.06-0.12,0.12-0.25,0.18-0.37c0.06-0.12,0.11-0.25,0.16-0.37c0.06-0.14,0.1-0.28,0.15-0.42
    c0.04-0.12,0.08-0.24,0.12-0.36c0.05-0.15,0.08-0.31,0.12-0.47c0.03-0.11,0.06-0.23,0.08-0.34c0.04-0.18,0.06-0.37,0.08-0.55
    c0.01-0.1,0.03-0.19,0.04-0.29c0.03-0.29,0.04-0.58,0.04-0.87l-0.06-10.36l-0.14-24.25c-0.01-2.46-1.05-4.68-2.7-6.27
    c-1.6-1.55-3.78-2.51-6.18-2.51h-0.01l-3.12,0.02l-3.33,0.02l-15.55,0.09l-3.08,0.02c-2.34,0.01-4.54,0.92-6.21,2.56
    c-0.02,0.02-0.04,0.04-0.06,0.06c-1.67,1.68-2.58,3.9-2.57,6.26l0.02,4.35L380.46,75.4z"/>
  <path d="M388.47,382.02c-1.43,0.06-2.87,0.11-4.33,0.16c-0.1,0-2.12,0.07-4.97,0.16c-0.33,0.01-0.66,0.02-1.01,0.03
    l-0.99,0.03c-0.58,0.02-1.15,0.03-1.73,0.06c-4.44,0.16-9.16,0.5-13.65,0.99c-4.23,0.46-7.71,0.98-11.29,1.7
    c-1,0.2-2.01,0.41-3.01,0.64c5.51,2.18,11.16,4,16.84,5.42c4.89,1.22,9.87,2.15,14.84,2.8c0.3,0.04,0.6,0.09,0.9,0.13
    c1.81-4.04,4.92-7.04,8.4-9.27c2.07-1.33,4.28-2.38,6.42-3.2C392.78,381.83,390.64,381.94,388.47,382.02z"/>
  <path d="M410,410.15c-1.47,0-2.83-0.79-3.55-2.06c-0.72-1.27-0.7-2.84,0.05-4.09c0.37-0.61,0.73-1.21,1.09-1.82
    c0.37-0.62,0.74-1.24,1.12-1.87c0.4-0.66,0.98-1.2,1.68-1.54l0.19-0.1c0.11-0.05,0.2-0.1,0.3-0.13c1.79-0.71,3.43-1.8,4.78-3.13
    l0-9.15c-2.66,0.21-5.61,0.42-8.95,0.63c-11.38,1.65-16.2,5.92-18.22,8.92c0.01,17.27,0.01,34.54,0.01,51.81
    c3.14-3.46,6.31-6.96,9.49-10.49c6.03-6.69,11.97-13.33,17.67-19.75l0-7.24L410,410.15z"/>
  <path d="M467.91,402.29c-0.05-1.83-1.33-3.41-3.11-3.84l-39.84-9.68l0-3.34c4.93-0.52,8.3-1.02,11.02-1.47
    c8.02-1.34,15.06-2.97,22.15-5.16c1.86-0.57,3.68-1.18,4.04-1.3c1.11-0.37,2.08-0.71,2.87-1c1.62-0.58,2.7-2.11,2.7-3.82l0-5.3
    c0-0.44-0.07-0.87-0.21-1.29c-0.56-1.66-2.11-2.77-3.87-2.77c0,0-24.7-0.02-38.71-0.03l-0.03-191.05l0-71.68
    c4.7-3.29,7.77-8.74,7.74-14.87l-0.2-34.62c-0.05-7.77-5.09-14.39-12.07-16.89l-0.11-19.53C420.22,6.57,413.55,0,405.39,0
    c-0.01,0-0.03,0-0.04,0l-7.82,0.04c-3.96,0.02-7.67,1.58-10.46,4.38c-2.79,2.8-4.31,6.51-4.29,10.44l0.11,19.52
    c-2.46,0.9-4.72,2.32-6.62,4.23c-3.29,3.3-5.12,7.65-5.23,12.28L79.89,224.88H52.24c-19.75,0-35.81,15.96-35.81,35.58v83.6
    c0,18.79,14.7,34.3,33.54,35.49c0.17,0.07,0.35,0.1,0.54,0.1h1.74l206.76,0c0.84,0,1.52-0.68,1.52-1.51v-79.02
    c0-0.61-0.37-1.16-0.94-1.39l-118.36-48.91l237.89-142.16l0.01,65.45c0,0.05-0.01,0.1-0.01,0.15l0.03,101.55
    c0.01,33.47,0.02,66.94,0.03,100.41c-0.4,0.01-0.82,0.03-1.26,0.04l-0.97,0.03c-0.61,0.02-1.22,0.03-1.81,0.06
    c-4.66,0.17-9.58,0.53-14.23,1.03c-4.46,0.49-8.17,1.04-12.01,1.81c-2.77,0.55-5.55,1.2-8.27,1.94c-1.74,0.47-2.98,2.05-3.01,3.84
    c-0.02,0.84-0.01,1.68,0,2.53c0.01,0.7,0.01,1.4,0.01,2.02c-0.03,0.44,0.01,0.87,0.13,1.29c0.32,1.21,1.16,2.18,2.31,2.68
    c7.22,3.14,14.72,5.7,22.28,7.59c5.54,1.38,11.19,2.41,16.84,3.1c0.01,19.9,0.01,39.79,0.02,59.69c0,1.56,0.94,2.94,2.39,3.51
    c0.44,0.17,0.9,0.26,1.38,0.26c1.06,0,2.07-0.45,2.78-1.22c6.15-6.72,12.6-13.82,19.17-21.11c6.51-7.23,12.94-14.41,19.09-21.35
    c0.61-0.69,0.95-1.58,0.95-2.51l0-9.3l38.96-0.01c1.09,0,2.16-0.45,2.92-1.22c0.77-0.78,1.19-1.85,1.16-2.94L467.91,402.29z
     M251.22,304.28v66.12l-197.73,0L52,370.38c-14.49-0.13-26.27-11.93-26.27-26.31v-83.6c0-14.52,11.89-26.34,26.51-26.34h13.91
    h15.32l0.19,0.08l39.39,16.28l10.27,4.25L251.22,304.28z M130.95,244.56l-39.39-16.28L371.08,61.26l0.14,24.78
    c0.03,4.53,1.73,8.77,4.79,12.09L130.95,244.56z M424.95,371.41c8.54,0,21.03,0.01,29.5,0.02c-6.31,1.88-12.65,3.33-19.82,4.52
    c-2.43,0.41-5.41,0.85-9.67,1.31c-2.58,0.28-5.62,0.57-9.3,0.87c-1.46,0.12-3.01,0.23-4.68,0.35c1.58-0.56,3.14-1.22,4.68-1.95
    c2.98-1.41,5.9-3.12,8.74-5.13C424.56,371.41,424.76,371.41,424.95,371.41z M388.42,103.98c0.33,0.02,0.65,0.03,0.98,0.03
    c0,0,0.1,0,0.11,0l25.05-0.14h0c0.35,0,0.7-0.04,1.05-0.06l0,68.44l0.03,195.12c-3.12,1.81-6.31,3.21-9.55,4.17
    c-5.48,1.63-11.4,2.1-17.62,2.37c-0.01-36.57-0.02-73.15-0.03-109.73l-0.02-89.99c0.01-0.05,0.01-0.11,0.01-0.16L388.42,103.98z
     M393.68,10.92c1.04-1.05,2.43-1.63,3.9-1.63l7.81-0.04h0.04c3.03,0,5.51,2.45,5.53,5.47l0.11,18.42l-18.88,0.11l-0.11-18.42
    C392.08,13.35,392.64,11.97,393.68,10.92z M382.89,45.12c0.02-0.02,0.04-0.04,0.06-0.06c1.66-1.64,3.86-2.55,6.21-2.56l3.08-0.02
    l15.55-0.09l3.33-0.02l3.12-0.02h0.01c2.4,0,4.58,0.96,6.18,2.51c1.65,1.59,2.69,3.82,2.7,6.27l0.14,24.25l0.06,10.36
    c0,0.29-0.01,0.58-0.04,0.87c-0.01,0.1-0.03,0.19-0.04,0.29c-0.02,0.19-0.05,0.37-0.08,0.55c-0.02,0.12-0.05,0.23-0.08,0.34
    c-0.04,0.16-0.07,0.32-0.12,0.47c-0.04,0.12-0.08,0.24-0.12,0.36c-0.05,0.14-0.1,0.28-0.15,0.42c-0.05,0.13-0.11,0.25-0.16,0.37
    c-0.06,0.12-0.11,0.25-0.18,0.37c-0.07,0.13-0.14,0.25-0.21,0.38c-0.06,0.11-0.13,0.22-0.2,0.33c-0.08,0.13-0.17,0.25-0.25,0.38
    c-0.07,0.09-0.14,0.19-0.21,0.28c-0.1,0.13-0.2,0.25-0.31,0.38c-0.07,0.08-0.13,0.15-0.2,0.22c-0.12,0.13-0.24,0.26-0.37,0.38
    c-0.06,0.06-0.12,0.11-0.18,0.16c-0.14,0.13-0.29,0.26-0.44,0.38c-0.04,0.03-0.08,0.06-0.12,0.09c-1.22,0.94-2.68,1.57-4.28,1.78
    c0,0,0,0,0,0c0,0,0,0,0,0c-0.36,0.05-0.73,0.08-1.1,0.08l-15.41,0.09l-9.65,0.05c-0.34,0.01-0.68-0.03-1.02-0.07
    c-0.32-0.03-0.63-0.09-0.93-0.16c-0.1-0.02-0.19-0.04-0.28-0.07c-0.26-0.07-0.52-0.14-0.77-0.23c-0.18-0.06-0.35-0.14-0.52-0.21
    c-0.18-0.08-0.37-0.15-0.55-0.24c-0.36-0.18-0.72-0.39-1.05-0.63c-0.4-0.28-0.79-0.59-1.15-0.95c-1.69-1.66-2.62-3.87-2.64-6.23
    l-0.06-10.59l-0.11-19.68l-0.02-4.35C380.31,49.02,381.22,46.79,382.89,45.12z M380.07,394.13c-0.3-0.04-0.6-0.09-0.9-0.13
    c-4.97-0.65-9.96-1.58-14.84-2.8c-5.68-1.42-11.33-3.24-16.84-5.42c1-0.23,2.01-0.44,3.01-0.64c3.58-0.71,7.06-1.24,11.29-1.7
    c4.49-0.49,9.21-0.83,13.65-0.99c0.58-0.02,1.15-0.04,1.73-0.06l0.99-0.03c0.35-0.01,0.68-0.02,1.01-0.03
    c2.85-0.09,4.87-0.15,4.97-0.16c1.46-0.05,2.9-0.1,4.33-0.16c2.16-0.09,4.3-0.2,6.42-0.37c-2.14,0.83-4.34,1.88-6.42,3.2
    C384.99,387.09,381.89,390.09,380.07,394.13z M397.98,437.14c-3.18,3.53-6.35,7.03-9.49,10.49c-0.01-17.27-0.01-34.54-0.01-51.81
    c2.02-2.99,6.84-7.26,18.22-8.92c3.33-0.21,6.29-0.42,8.95-0.63l0,9.15c-1.35,1.33-2.99,2.42-4.78,3.13
    c-0.09,0.04-0.19,0.08-0.3,0.13l-0.19,0.1c-0.7,0.34-1.27,0.88-1.68,1.54c-0.38,0.62-0.75,1.25-1.12,1.87
    c-0.36,0.61-0.72,1.21-1.09,1.82c-0.76,1.25-0.78,2.82-0.05,4.09c0.72,1.27,2.09,2.06,3.55,2.06l5.65,0l0,7.24
    C409.95,423.81,404.01,430.45,397.98,437.14z M424.95,402.03l-4.45,0c0.46-0.41,0.91-0.83,1.33-1.27c1.09-1.13,2.05-2.37,2.87-3.7
    l0.24,0.06l20.18,4.9L424.95,402.03z"/>
</g>
</svg>

</template>

<script>
export default {
  data: () => {
    return {
      zero: 0,
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "64px",
    },
    height: {
      type: String,
      default: "64px",
    }
  },
};
</script>

<style scoped>
.techno-icon, .techno-icon g, .techno-icon path {
  stroke: #009f4d !important;
  stroke-width: 8px !important;
  fill: white !important;
}

:not(.techno-icon) {
  stroke: black;
  stroke-width: 6px;
  fill: white;
}

</style>