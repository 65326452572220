const axios = require('axios');

import { PLATE_FORMS, AVAILABLE_COUNTRIES } from "@/enums";
import i18n from "@/i18n";

const replaceTextLinksByHrefs = (content) => {
    // eslint-disable-next-line
    var exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    var element_content=content.replace(exp_match, '<a target="_blank" href="$1">$1</a>');
    // eslint-disable-next-line
    var new_exp_match =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
    var new_content=element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2">$2</a>');
  
    return new_content;
}

function displayPaginationInfo(pageSize, currentRow, currentPage, totalRows, totalPages, localScope) {
  if (totalRows > localScope.totalCount) totalRows = localScope.totalCount;
  let linesText;
  let pageText = `${i18n.t('tables.pagination.info.page')} <strong>${currentPage}</strong> / ${totalPages} - `;
  if (totalRows < 1) {
    linesText = i18n.t('tables.pagination.info.noresult');
    pageText = '';
  }
  else if (totalRows == 1) {
    linesText = `${i18n.t('tables.pagination.info.line')} <strong>${currentRow}</strong> / ${totalRows}`;
  }
  else {
    linesText = `${i18n.t('tables.pagination.info.lines')} <strong>${currentRow} - ${(currentRow + pageSize - 1 <= totalRows ? currentRow + pageSize - 1 : totalRows)}</strong> / ${totalRows}`;
  }
  return `${pageText}${linesText}`;
}

function sortByProperty(array, property) {
    array.sort(function (a, b) {
      if ((a[property] == null || a[property] == '' || a[property] == undefined) && (b[property] == null || b[property] == '' || b[property] == undefined)) {
          return 0;
      }
      if (a[property] == null || a[property] == '' || a[property] == undefined) {
          return 1;
      }
      if (b[property] == null || b[property] == '' || b[property] == undefined) {
          return -1;
      }
      if (a[property].toUpperCase() < b[property].toUpperCase()) {
          return -1;
      }
      if (a[property].toUpperCase() > b[property].toUpperCase()) {
          return 1;
      }
      return 0;
  });
}

function fieldSorter(fields) {
  return function (a, b) {
      return fields
      .map(function (o) {
        var dir = 1;
        if (o[0] === "-") {
          dir = -1;
          o = o.substring(1);
        }
        if ((a[o] == null || a[o] == '' || a[o] == undefined) && (b[o] == null || b[o] == '' || b[o] == undefined)) {
          return 0;
        }
        if (a[o] == null || a[o] == '' || a[o] == undefined) {
          return 1;
        }
        if (b[o] == null || b[o] == '' || b[o] == undefined) {
          return -1;
        }

        if (isNumeric(a[o]) && isNumeric(b[o])) {
          if (a[o] > b[o]) {
            return dir;
          }
          if (a[o] < b[o]) {
            return -dir;
          }
          return 0;
        }

        if (a[o].toString().toUpperCase() > b[o].toString().toUpperCase()) return dir;
        if (a[o].toString().toUpperCase() < b[o].toString().toUpperCase()) return -dir;
        return 0;
      })
      .reduce(function firstNonZeroValue(p, n) {
        return p ? p : n;
      }, 0);
  }
}

function sortByProperties(array, fields) {
  array.sort(fieldSorter(fields));
}

function sortByInteger(array, property) {
    array.sort(function (a, b) {
        if (a[property] > b[property]) {
            return 1;
          }
          if (a[property] < b[property]) {
            return -1;
          }
          return 0;
    });
}

function parseError(error) {
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        return error.response.data.message;
      }
      else {
        return error.response.data;
      }
    }
    else if (error.message) {
      return error.message;
    }
    else {
      return error.toString();
    }
  }

  
  function createUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  function updateFunctionTranslation(functions, language) {
    if (!functions) {
      return;
    }
    for(var j = 0; j < functions.length; j++){
      if (functions[j].functions_translations) {
        let translation = functions[j].functions_translations.find(x => x.language == language);
        var translatedFunctionName = functions[j].name;
        if (translation) {
          translatedFunctionName = translation.name;
        }
        
        functions[j].translatedName = translatedFunctionName
      }
    }
  }

  function getLongFormattedAddress(countryName, countryId, stateName, stateCode, address) {

      let formattedAddress = "";
      if (!address) {
          return formattedAddress;
      }

      if (countryId && (countryId == AVAILABLE_COUNTRIES.FRANCE || countryId == AVAILABLE_COUNTRIES.BELGIUM || countryId == AVAILABLE_COUNTRIES.CANADA)) {
        formattedAddress += address.address2 ? address.address2 : "";
        formattedAddress += address.address1
          ? (formattedAddress ? ", " : "") + address.address1
          : "";
      }
      else {
        formattedAddress += address.address1 ? address.address1 : "";
        formattedAddress += address.address2
          ? (formattedAddress ? ", " : "") + address.address2
          : "";
      }

      if (countryId && (countryId == AVAILABLE_COUNTRIES.FRANCE || countryId == AVAILABLE_COUNTRIES.BELGIUM)) {
        formattedAddress += address.postalCode
          ? (formattedAddress ? ", " : "") + address.postalCode
          : "";
          formattedAddress += address.city
          ? (formattedAddress ? " " : "") + address.city
          : "";
      }
      else {
        formattedAddress += address.city
          ? (formattedAddress ? ", " : "") + address.city
          : "";
        if (countryId && countryId ==  AVAILABLE_COUNTRIES.UNITED_STATES) {
          formattedAddress += stateCode
          ? (formattedAddress ? ", " : "") + stateCode
          : "";
        }
        else {
          formattedAddress += stateName
            ? (formattedAddress ? ", " : "") + stateName
            : "";
        }
        formattedAddress += address.postalCode
          ? (formattedAddress ? ", " : "") + address.postalCode
          : "";
      }
      formattedAddress += countryName
          ? (formattedAddress ? ", " : "") + countryName
          : "";

      return formattedAddress;
  }

  function getFormattedForm(form) {
    switch (form) {
      case PLATE_FORMS.SQUARE:
        return this.$t('reportInfo.square');
      case PLATE_FORMS.U:
        return this.$t('reportInfo.u');
      case PLATE_FORMS.L:
        return this.$t('reportInfo.l');
      case PLATE_FORMS.BOX:
        return this.$t('reportInfo.box');
      case PLATE_FORMS.UNDERPINNING:
        return this.$t('reportInfo.underpinning');
      default:
        return '';
    }
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  
  function setLocalStorageWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  function getLocalStorageWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }


  function getLanguage() {
    return localStorage.getItem("language") || 'enCA';
  }

  function getLanguageWithoutRegion(language) {
    // Get the language without its regionalization. To do so, get only lowercase characters.
    let languageWithoutRegion = "";
    for (var i = 0; i < language.length; i++) {
      let char = language.charAt(i);
      if (char == char.toLowerCase()) {
        languageWithoutRegion += char;
      }
      else {
        break;
      }
    }
    return languageWithoutRegion;
  }

  function translateFormula(formula) {
    return formula.replaceAll('SI(', 'IF(').replaceAll('ET(', 'AND(').replaceAll('OU(', 'OR(').replaceAll('NON(', 'NOT(').replaceAll('OUX(','XOR(');
  }

  function formatDealerSupportingPlates(dealer) {
      dealer.dealerSupportingPlates = [];
      dealer.supportingPlates.forEach(p => {
        dealer.dealerSupportingPlates.push({ id: p.id, name: p.name, translatedName: p.translatedName, mobility: p.mobility, form: p.form, index: p.index, selected: false });
      })

      // Pour chaque dealer, on vient ajouter ses plaques standard sélectionnées
      if (dealer.standard_supporting_plates) {
        for (let i = 0; i < dealer.standard_supporting_plates.length; i++) {
          let plateId = dealer.standard_supporting_plates[i].id;
          let dealerSupportingPlate = dealer.dealerSupportingPlates.find(x => x.id == plateId);
          if (dealerSupportingPlate) {
            dealerSupportingPlate.selected = true;
          }
        }
    }
  }

  function getFirstDayOfWeek() {

    switch (getLanguage()) {
      case 'frFR':
      case 'frBE':
        return 1;
      default:
        return 0;
    }
  }

  function getFormattedName(firstname, lastname) {
    let formattedName = '';
    if (firstname && firstname.length > 0) {
      formattedName += firstname;
    }
    if (lastname && lastname.length > 0) {
      formattedName += (formattedName ? ' ' : '') + lastname;
    }
    return formattedName;
  }

  function getTabulatorLangsObject(i18n, columnsLangData) {
    return {
      "fr": {
        "columns": columnsLangData,
        "data":{
          "loading": i18n.t("tables.data.loading"),
          "error": i18n.t("tables.data.error")
        },
        "groups": {
          "item": i18n.t("reports.table.item"),
          "items": i18n.t("reports.table.items"),
        },
        "pagination": {
          "page_size": i18n.t("tables.pagination.page_size"),
          "page_title": i18n.t("tables.pagination.page_title"),
          "first": i18n.t("tables.pagination.first"),
          "first_title": i18n.t("tables.pagination.first_title"),
          "last": i18n.t("tables.pagination.last"),
          "last_title": i18n.t("tables.pagination.last_title"),
          "prev": i18n.t("tables.pagination.prev"),
          "prev_title": i18n.t("tables.pagination.prev_title"),
          "next": i18n.t("tables.pagination.next"),
          "next_title": i18n.t("tables.pagination.next_title"),
          "all": i18n.t("tables.pagination.all"),
          "counter":{
              "showing": i18n.t("tables.pagination.showing"),
              "of": i18n.t("tables.pagination.of"),
              "rows": i18n.t("tables.pagination.rows"),
              "pages": i18n.t("tables.pagination.pages"),
          }
        }
      },
      "en": {
        "columns": columnsLangData
      }
    };
  }
  
  function objectMatchSearch(object, search, keys) {
    search = formatForCompare(search);
    for (let key of keys) {
      if (formatForCompare(object[key]).includes(search)) {
          return true;
      }
    }
    return false;
  }

  function formatForCompare(str) {
      if (str == null || str == undefined) {
          return '';
      }
      return str.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  //returns a closure so you can manage the timeout directly, including triggering it earlier than configured
  function createTimeout(timeoutHandler, delay) {
    var timeoutId;
    timeoutId = setTimeout(timeoutHandler, delay);
    return {
      clear: function () {
        clearTimeout(timeoutId);
      },
      trigger: function () {
        clearTimeout(timeoutId);
        return timeoutHandler.apply(timeoutHandler, arguments);
      }
    };
  }

  function sleep(ms) {
    return new Promise(r => setTimeout(r, ms));
  }

  function buildParamsUrl(params, isFirstInUrl) {
    if (params == null || Object.keys(params).length < 1) return '';
    isFirstInUrl = isFirstInUrl === false ? '&' : '?';
    return isFirstInUrl + Array.from(Object.entries(params), ([k, v]) => `${k}=${v}`).join('&');
  }

  async function getAddressFromGooglePlace(place) {
    let response = await axios.post('/api/utils/getAddressFromGooglePlace', place);
    console.log(response);
    return response;
  }

  async function getAddressFromLngLat(place) {
    let response = await axios.post('/api/utils/getAddressFromLngLat', place);
    console.log(response);
    return response;
  }

  function numberOnlyValidation(event) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

const groupBy = (items, properties, collect) => {
    // TODO argument validation
    var groups = _groupBy(items, properties);
    // collect other properties values in array
    if (collect && collect.length > 0)
      groups = collectProperties(groups, collect);
  
    return groups;
  
  
    function _groupBy(items, properties) {
      var group = {};
       if (typeof properties[0] === 'string') {
         group = groupByCategory(items, properties[0]);
       } else {
         group = groupByRange(items, properties[0]);
       }
      properties = properties.slice(1);
      if (properties.length > 0) {
        for (var key in group) {
          group[key] = _groupBy(group[key], properties);
        }
      }
      return group;
    }
    
    function groupByCategory(arr, prop) {
      return arr.reduce(function(group, item) {
        var tags = valueAt(item, prop);
        tags.forEach(function(tag) { 
          group[tag] = group[tag] || [];
          group[tag].push(item);
        });
        return group;
      },{});
    }
    
    function groupByRange(arr, lookup) {
      return arr.reduce(function(group, f) {
        var val, ind, tag;
        val = valueAt(f, lookup.property);
        ind = locationOf(val, lookup.intervals);
        if (ind === lookup.intervals.length -1) ind--;
        tag = lookup.labels ? lookup.labels[ind] : ind;
        group[tag] = group[tag] || [];
        group[tag].push(f);
        return group;
      },{});
    }
    
    // collect the properties in an array 
    function collectProperties(groups, properties) { 
      var collection = {};
      for (var key in groups) {
        if (Array.isArray(groups[key])) {
          collection[key] = groups[key].reduce(function(coll, item) {
            properties.forEach(function(prop) { 
              if (!coll[prop]) coll[prop] = [];
              coll[prop] = coll[prop].concat(valueAt(item,prop));
            })
            return coll;
          }, {})
        } else {
          collection[key] = collectProperties(groups[key], properties);
        }
      }
      return collection;
    }
    
    // similar to Array.findIndex but more efficient
    // http://stackoverflow.com/q/1344500/713573
    function locationOf(element, array, start, end) {
      start = start || 0;
      end = end || array.length;
      var pivot = parseInt(start + (end - start) / 2, 10);
      if (end-start <= 1 || array[pivot] === element) return pivot;
      if (array[pivot] < element) {
        return locationOf(element, array, pivot, end);
      } else {
        return locationOf(element, array, start, pivot);
      }
    }
  
    function valueAt(obj, path) {
      var parts = path.split('.')
      var prop = parts.shift()
      if (isPrimitive(obj[prop])) {
        return [obj[prop]]
      } else if (isObject(obj[prop])) {
        return valueAt(obj[prop], parts.join('.'))
      } else if (isPrimitiveArray(obj[prop])) {
        return obj[prop].reduce(function(values, val) {
          if (values.indexOf(val) < 0) values.push(val)
          return values
        },[])
      } else if (isObjectArray(obj[prop])) {
        return obj[prop].reduce(function(values, item) {
          var vals = valueAt(item, parts.join('.'))
          vals.reduce(function(sum, key) {
            if (sum.indexOf(key) < 0) sum.push(key)
            return sum
          }, values)
          return values
        }, [])
      } else {
        throw new TypeError('property path: "'+ path + '" not valid in your data at object: '+ JSON.stringify(obj))
      }
    }
    
    function isPrimitive(val) {
      return typeof val === 'string' || typeof val === 'number' 
        || typeof val === 'boolean' 
    }
    
    function isPrimitiveArray(val) {
      return Array.isArray(val) && val.length > 0 && isPrimitive(val[0])
    }
    
    function isObject(val) {
      // `typeof [{id: 1}]` returns value object so frst negate array 
      return !Array.isArray(val) && typeof val === 'object'
    }
    
    function isObjectArray(val) {
      return Array.isArray(val) && val.length > 0 && typeof val[0] === 'object' 
    }
  
  }

  export {
    replaceTextLinksByHrefs,
    sortByProperty,
    sortByProperties,
    sortByInteger,
    parseError,
    fieldSorter,
    createUuid,
    updateFunctionTranslation,
    getLongFormattedAddress,
    getFormattedForm,
    isNumeric,
    setLocalStorageWithExpiry,
    getLocalStorageWithExpiry,
    getLanguage,
    getLanguageWithoutRegion,
    translateFormula,
    formatDealerSupportingPlates,
    getFirstDayOfWeek,
    getFormattedName,
    getTabulatorLangsObject,
    objectMatchSearch,
    displayPaginationInfo,
    createTimeout,
    sleep,
    buildParamsUrl,
    getAddressFromGooglePlace,
    getAddressFromLngLat,
    numberOnlyValidation,
    groupBy
};
