<template>
  <center>
     <img
     :src="'/assets/svg/'+ $t('general.company_name') + '.svg'"
      height="40"
    />
   
    <br />
    <h3>{{ $t('contract.refused_error') }}</h3>
    
  </center>
</template>

<script>

  export default {
    name: 'ContractRefused',
    updated() {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('returnurl');
        
        if (myParam && myParam.startsWith('/')) {
        this.$router.push(myParam)
        }
        else {
        this.$router.push("/");
        }
    }
  }

</script>

<style scoped>

  center {
    margin: 10vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>