<template>
    <v-dialog :persistent="cancelFunction ? true : false"
        max-width="800"
        v-model="open"
    >
        <v-card>
            <v-card-title>
                <div id="contract" v-html="compiledHtml"></div>
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="handleCancel()" v-if="cancelFunction">
                    {{ cancelName }}
                </v-btn>
                <v-btn color="success" @click="handleAction()" v-if="actionFunction">
                    {{ actionName }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
    name: "ContractDialog",
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        title: {
            type: String,
            default: "Confirmation",
        },
        description: {
            type: String,
            required: true
        },
        cancelName: {
            type: String,
            default: "NO"
        },
        cancelFunction: {
            type: Function,
            required: false
        },
        actionName: {
            type: String,
            default: "YES"
        },
        actionFunction: {
            type: Function,
            required: false
        },
    },
    data: function() {
        return {
            contractHtml: ""
        };
    },
    computed: {
        open: {
            // getter
            get() {
                return this.value
            },
            // setter
            set(newValue) {
                this.$emit('input', newValue)
            }
        },
        compiledHtml: function() {
          return this.contractHtml;
        }
    },
     watch: {
        open(visible) {
            if (visible) {
                axios.get('/assets/contract-files/' + this.description)
                .then(result => {
                    this.contractHtml = result.data;
                })
                .catch(error => {
                    console.error("error getting file: " + error);
                });
            }
        }
    },
    methods: {
        closeDialog() {
            this.open = false
        },
        handleCancel() {
            if(this.cancelFunction instanceof Function)
                this.cancelFunction();

            this.closeDialog();
        },
        handleAction() {
            if(this.actionFunction instanceof Function)
                this.actionFunction();

            this.closeDialog();
        },
    }
}
</script>

<style>
#contract {
    font-size: 14px;
    line-height: 21px;
}
#contract h1 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top:25px;
  margin-bottom:25px;
  text-align: center;
}
#contract h2 {
  font-weight: bold;
  margin-top:25px;
  margin-bottom:10px;
  font-size: 1em;
}
#contract p {
  margin-bottom:15px;
}
#contract a {
  color:#009f4d;
}
</style>