<template>
  <v-app data-app class="background-secondary full-height">
    <router-view />
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { getLanguageWithoutRegion } from '@/utils/utils'
import {DateTime} from 'luxon'
window.DateTime = DateTime

export default {
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
  },
  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['setUserFunctions', 'setUserRoles', 'setSocket']),
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;

      let isMobile = this.window.width < 960; // S'assurer que ce soit le même que le Vuetify mobileBreakpoint
      if (this.isMobile != isMobile) {
        this.$store.dispatch("setIsMobile", isMobile);
      }
    },
    initializeIcons() {
      //console.log('Initializing icons...')
        document.querySelectorAll("img.svg").forEach(function (img) {
        var imgID = img.id;
        var imgClass = img.className;
        var imgURL = img.src;

        fetch(imgURL)
          .then(function (response) {
            return response.text();
          })
          .then(function (text) {
            var parser = new DOMParser();
            var xmlDoc = parser.parseFromString(text, "text/xml");

            // Get the SVG tag, ignore the rest
            var svg = xmlDoc.getElementsByTagName("svg")[0];

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== "undefined") {
              svg.setAttribute("id", imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== "undefined") {
              svg.setAttribute("class", imgClass + " replaced-svg");
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            svg.removeAttribute("xmlns:a");

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if (
              !svg.getAttribute("viewBox") &&
              svg.getAttribute("height") &&
              svg.getAttribute("width")
            ) {
              svg.setAttribute(
                "viewBox",
                "0 0 " +
                  svg.getAttribute("height") +
                  " " +
                  svg.getAttribute("width")
              );
            }

            // Replace image with new SVG
            img.parentNode.replaceChild(svg, img);
          });
      });
    },
    initializeLanguage() {
      console.log('Initializing languages...')
      let language = localStorage.getItem("language");

      // If there is already a preferred language stored
      if (language) {
        
        // Retrocompatibility for english 'en' and 'fr', 'en' -> 'enCA' & 'fr' -> 'frCA'
        if (language == "en") { 
          console.log('Retrocompatibility: Selecting en-CA')
          language = "enCA";
          localStorage.setItem("language", language);
        } else if (language == 'fr') {
          console.log('Retrocompatibility: Selecting fr-CA')
          language = "frCA";
          localStorage.setItem("language", language);
        }
      }
      else {
        // Default language
        console.log('No language set: Selecting default en-CA')
        language = "enCA";
        localStorage.setItem("language", language);
      }

      this.$root.$i18n.locale = language;
      console.log('Selected language', language)

      // Vuetify use BCP 47 norms. fr-CA en-US... 
      this.$root.$vuetify.lang.current = getLanguageWithoutRegion(language);

      // load FR language for embedded PDF viewer if user uses french
      // Using previous version of language file (vue-pdf-app uses pdf.js files, but is not updated for a while): https://github.com/mozilla/pdf.js/blob/v3.0.279/l10n/fr/viewer.properties
      if (language.startsWith('fr')) {
        setTimeout(() => {
          let lnk = document.createElement('link');
          lnk.rel = 'resource';
          lnk.type = 'application/l10n';
          lnk.href = '/pdf-viewer-fr.properties';
          let head = document.querySelector('head');
          head.appendChild(lnk);
        }, 100);
      }
    }
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0,
      },
      showAbout: false,
      locale: this.$i18n.locale,
    };
  },
  created: function () {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    console.log("Mounted App.vue")

    this.setSocket(this.$socket);

    this.$socket.on('connect', () => {
      console.log('socket connected', this.$socket.id);

      window.__socketId = this.$socket.id;

      let user = localStorage.getItem("user");
      let userId = user ? JSON.parse(localStorage.getItem('user')).id : null;

      let build = localStorage.getItem("build");
      this.$socket.emit('connected', { build, userId })
    });
    this.$socket.on('buildNumber', (build) => {
      console.log('New build number: ' + build)
      localStorage.setItem("build", build);
      window.location.reload(true)
    });
    this.$socket.on('logout', () => {
      console.log('Logout request received from the server!')
      // On ne repasse pas le socket pour ne pas renvoyer plusieurs fois la requête logout
      this.logout();
    });
    this.$socket.on('functions', (functions) => {
      console.log('USER FUNCTIONS CHANGED', functions)
      this.setUserFunctions(functions);
    });
    this.$socket.on('roles', (roles) => {
      console.log('ROLES CHANGED', roles)
        this.setUserRoles(roles);
    });

    this.initializeLanguage();
    this.initializeIcons();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  metaInfo() {
      return {
          title: this.$t('general.app_name'),
          titleTemplate: '%s - ' + this.$t('general.company_name'),
      }
  }
};
</script>
