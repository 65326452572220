<template>
    <v-col cols="12" xs="6" sm="4" md="4" lg="3" xl="3" align="center">
      <v-hover v-slot="{ hover }" class="card-border10">
        <v-card :elevation="hover ? 5 : 2" @click="$router.push(url)">
            <div style="height: 140px">
                <slot/>
            </div>

            <h4>{{ title }}</h4>
        </v-card>
      </v-hover>
    </v-col>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
.card-border10 {
  border-radius: 10px;
  height: 175px;
}
</style>