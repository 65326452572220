<template>
  <div>

    <NavDrawer />

    <AppBar/>

    <router-view />
    <v-snackbar v-model="showToast" timeout="-1" style="white-space:pre-wrap;">
      <span v-html="toastMessage"></span>
      <template v-slot:action="{ attrs }">
        
        <v-btn :color="toastButtonColor" text v-bind="attrs" @click="closeToast">{{ $t("general.close") }}</v-btn>
      </template>
    </v-snackbar>

    <ContractDialog
      v-model="showContractDialog"

      :title="$t('contract.head')"
      :description="$t('contract.contract_file_name')"

      :cancelName="$t('contract.refuse')"
      :cancelFunction="refuseContract"
      :actionName="$t('contract.accept')"
      :actionFunction="acceptContract"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import AppBar from './components/AppBar'
import NavDrawer from './components/NavDrawer'
import ContractDialog from "@/components/dialogs/ContractDialog"
import UserService from './services/user.service'

export default {
  components: {
    AppBar,
    NavDrawer,
    ContractDialog,
  },
  computed: {
    ...mapState({
       drawer: state => state.drawer,
       breadcrumbs: state => state.breadcrumbs,
       isMobile: state => state.isMobile,
       showToast: state => state.showToast,
       toastMessage: state => state.toastMessage,
       toastButtonColor: state => state.toastButtonColor,
       currentUser: state => state.currentUser,
       isDataInitialized: (state) => state.isDataInitialized
    }), 
    ...mapGetters({
    }),
    drawerEdit: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.SET_NAV(value);
      }
    },
    iconWidth: () => '25px',
    iconHeight: () => '25px',
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations([
      'closeToast', 'openToast', 'SET_NAV'
    ]),
    refuseContract() {
      let returnUrl = window.location.pathname;
      this.$router.push("/contract-refused?returnurl=" + returnUrl)
    },
    async acceptContract() {
      this.currentUser.contractVersion = process.env.VUE_APP_CONTRACT_VERSION;
      this.currentUser.changes = {
        addedOrModifiedAddresses: [],
        deletedAddresses: [],
        addedDealers: [],
        deletedDealers: [],
        addedRoles: [],
        deletedRoles: [],
        addedDealerGroups: [],
        deletedDealerGroups: [],
        addedEngineeringDepartments: [],
        deletedEngineeringDepartments: []
      };
      this.currentUser.changes.contractVersion = this.currentUser.contractVersion;
      this.currentUser.changes.contractDate = new Date();
      let response = await UserService.saveUserChanges(this.currentUser);
      if (response.status != 200) {
        this.openToast({ message: response, duration: 15000, color: 'red'});
      }
    }
  },
  data() {
    return {
      showAbout: false,
      showNotif: false,
      locale: this.$i18n.locale,
      showContractDialog: false,
      selectedNav: null
    };
  },
  mounted() {
    //console.log('MasterPage mounted');

    const handleContract = setInterval(() => {
      if (this.isDataInitialized) {
        clearInterval(handleContract)
        this.showContractDialog = this.currentUser && (!this.currentUser.contractVersion || this.currentUser.contractVersion != process.env.VUE_APP_CONTRACT_VERSION);
      }
    }, 500)
  }
};
</script>

<style>
#appbar-logo{
  margin-left:10px; margin-right:10px; margin-top:5px;
}
#avatar-btn{
  color:#fff!important;
  margin-right:5px;
}
#app-bar{
  position: fixed;
  background:white;
  padding-right:10px;
}
.v-badge__badge{
  top:-14px!important;
  margin-left: -3px;
}

#nav-drawer{
  padding-top:56px;
  position: fixed;
}

.v-list-item__title{
  font-size:14px!important;
  line-height: 17px!important;
}
.svg-inline--fa{
  color:rgba(0, 0, 0, 0.54);
  margin-left:2px;
  margin-right:2px;
}
.space-btn{
  margin-left:10px;
  margin-right:10px;
}
.shrink-content{
  margin-left:70px;
}

.no-transform {
  text-transform: none!important;
}
</style>