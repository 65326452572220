<template>
  <v-menu
          @input="handleMenuInput"
          attach="#app-bar"
          :nudge-width="0"
          offset-y
          left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" @click="load();">
        <v-badge :color="isDevelopment ? 'grey' : 'error'" :content="badgeNumber" :value="badgeNumber > 0">
          <v-icon style="color:rgba(0, 0, 0, 0.54);">mdi-bell</v-icon></v-badge>
      </v-btn>
    </template>
    <v-card style="width:600px;" class="pa-2 pt-0" id="infinite-list">
      <span class="btn-header">
        <v-menu offset-y left width="250px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" :icon="true" density="compact" variant="outlined">
              <v-icon style="color:black;">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item href="/notificationsConfig">
              <v-list-item-icon>
                <v-icon size="28">mdi-cog-outline</v-icon>
              </v-list-item-icon>
              {{$t('notifications.notificationSettings')}}
            </v-list-item>
            <v-list-item @click.once="markAllAsRead()" href="#">
              <v-list-item-icon>
                <v-icon size="28">mdi-check-all</v-icon>
              </v-list-item-icon>
              {{$t('notifications.markAllAsRead')}}
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
      <div class="list-group-wrapper" v-if="items && items.length > 0">
        <transition name="fade">
          <div class="loading" v-show="loading">
            <v-icon size="100">mdi-loading mdi-spin</v-icon>
          </div>
        </transition>
        <div class="list-group">
          <div v-for="item in items" v-bind:key="item.id"
               :class="['list-group-item', 'px-2', 'py-4', (item.link?'cursor-pointer':'default'), item.read == false ? 'unread' : '']"
               @click="goToLink(item)">
            <div class="marker-and-html">
              <div v-if="item.read == false" class="unread-marker"></div>
              <div v-else class="spacer-marker">&nbsp;</div>
              <div v-html="item.html"></div>
            </div>
            <div class="text-right"> <span class="dateTimeFromNow">{{ item.dateTimeFromNow }}</span> {{
            $t('notifications.by') }} {{ item.by }}</div>
          </div>
        </div>
      </div>
      <div v-else class="no-notification">
        {{ $t('notifications.none') }}
      </div>
    </v-card>
  </v-menu>

</template>
<script>
import { mapState, mapMutations } from "vuex";
import moment from 'moment';
import 'moment/locale/fr';
import NotificationService from "@/services/notification.service";
import { getLanguage } from '@/utils/utils';

export default {
  async mounted() {
    this.$socket.on('resetBadgeNumber', () => {
      this.setBadgeNumber(0);
      console.log('resetBadgeNumber');
    });

    this.$socket.on('newNotification', () => {
      this.setBadgeNumber(this.badgeNumber + 1);
      console.log('newNotification');
    });

    let self = this;

    this.$socket.on('newDesktopNotification', async(data) => {
      let notify;
      let onclick = () => { 
        if (self.badgeNumber) self.setBadgeNumber(self.badgeNumber - 1);
        self.goToLink({
          id: data.id,
          read: false,
          link: data.link
        });
      };
      let text = self.$t('general.app_name');
      let item = data.texts[getLanguage()];
      let message = item.text?.length > 0 ? item.text : item.html;
      if (data.byFullName) message += ' ' + self.$t('notifications.by') + ' ' + data.byFullName;
      let params = { body: message, icon: '/img/icons/android-chrome-192x192.png'};
      
      if (!window.Notification) {
            console.log('Browser does not support notifications.');
        } else {
            // check if permission is already granted
            if (Notification.permission === 'granted') {
                // show notification here
                notify = new Notification(text, params);
                notify.onclick = onclick;
            } else {
                // request permission from user
                let permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    // show notification here
                    notify = new Notification(text, params);
                    notify.onclick = onclick;
                } else {
                    console.log('User blocked notifications.');
                }
            }
        }
      console.log('newDesktopNotification');
    });

    let momentLocal = 'en';
    //TODO: Déplacer le set locale de moment dans
    let language = this.$i18n.locale;
    if (language && language.indexOf('fr') >= 0) momentLocal = 'fr';
    moment.locale(momentLocal);
  },

  computed: {
    ...mapState({
      badgeNumber: (state) => state.badgeNumber
    })
  },
  methods: {
    ...mapMutations(['setBadgeNumber', 'openToast']),
    log: console.log,

    markAllAsRead() {
      NotificationService.markAsRead(this.items.reduce((prev, current) => prev && prev.id > current.id ? prev : current), true, this.items)
    },
    // scrollTop on close menu
    handleMenuInput(input) {
      const closing = !input;
      if (closing) {
        this.start = 0;
        document.querySelector('.list-group').scrollTop -= 10000;
      }
    },
    goToLink(item) {
      if (!item.read) NotificationService.markAsRead(item);
      if (item.link) {
        let currentLink = this.$router.currentRoute.path;
        if (currentLink != item.link) {
          this.$router.push(item.link)
        }
        else {
          this.openToast({ message: this.$t('notifications.errors.youAreAlreadyOnThisPage'), duration: 5000 });
        }
      }
    },
    async load() {
      try {
        // Ne pas faire le load sur le close
        if (this.start) {
          this.start = 0;
          return false;
        }

        this.start = 0;
        this.items = [];
        let notifications = await NotificationService.get(this.start, this.nb);
        this.setBadgeNumber(0);
        this.waitForElement("#infinite-list", () => {
          notifications.data.data.forEach(e => {
            e.dateTimeFromNow = moment(e.dateTime).fromNow();
            this.items.push(e);
          });
          this.nextItem = 1;
          const listElm = document.querySelector('#infinite-list');
          listElm.addEventListener('scroll', () => {
            if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
              this.loadMore();
            }
          });
          this.start += this.nb;
        });
      } catch (error) {
        console.log(error);
        this.openToast({ message: error, duration: 5000, color: 'red' });
      }
    },
    async loadMore() {
      try {
        let a = await NotificationService.get(this.start, this.nb);
        this.loading = true;
        a.data.data.forEach(e => {
          e.dateTimeFromNow = moment(e.dateTime).fromNow();
          this.items.push(e);
        });
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.openToast({ message: error, duration: 5000, color: 'red' });
      }

    },
    waitForElement(selector, callback) {
      if (document.querySelector(selector)) {
        callback();
      } else {
        setTimeout(() => this.waitForElement(selector, callback), 100);
      }
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      nextItem: 1,
      items: [],
      start: 0,
      nb: 25,
      isDevelopment: (process.env.VUE_APP_GIT_BRANCH !== "master" && process.env.VUE_APP_GIT_BRANCH !== "")
    };
  }
};

</script>

<style scoped>
.list-group-wrapper {
  position: relative;
  font-size: 0.90em;
}

.list-group {
  overflow: auto;
  height: 90vh;
}

.list-group-item:nth-child(odd) {
  background-color: #E8F5E9;
}

.loading {
  text-align: center;
  position: absolute;
  color: #009f4d;
  z-index: 9;
  padding: 8px 18px;
  border-radius: 5px;
  left: calc(50% - 70px);
  top: calc(50% - 70px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}

.bg-green-lighten-5 {
  background-color: #000;
}

.dateTimeFromNow {
  color: #009f4d;
  font-weight: bold;
}

.btn-header {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0;
  border-bottom: 1px solid lightgray;
  margin-bottom: 2px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-notification {
  padding: 5px;
  text-align: center;
  min-height: 50px;
  justify-content: center;
  display: flex;
  line-height: 50px;
}

.unread * {
  font-weight: bold;
}

.marker-and-html {
  display: flex;
}

.unread-marker {
  display: flex;
  min-width: 15px;
  min-height: 15px;
  border-radius: 7.5px;
  background-color: #009f4d;
  margin-right: 10px;
  align-self: center;
}

.spacer-marker {
  display: flex;
  width: 25px;
}

</style>
