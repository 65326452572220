<template>
  <div style="height:100%;background:#F1F1F1;">
    <v-app-bar absolute style="background:white;">
      <img :src="'/assets/svg/'+ $t('general.company_name') + '.svg'" id="login-logo" />
      <h1 class="hidden-sm-and-down" id="h1-title">{{$t("general.app_name")}}</h1>
      <v-toolbar-title></v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu v-model="showAbout" :close-on-content-click="false" :nudge-width="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>

        <v-card style="padding:10px; border-radius:8px;">
          <h2>{{ $t("login.about") }}</h2>
         <v-divider></v-divider>
          <p position="stacked" style="margin-bottom: 0px;">{{ $t("login.by_phone") }}</p>
          <a class="text-techno" href="tel:418-755-0294">418-755-0294</a>

          <p style="margin-top:10px;margin-bottom:0px;">{{ $t("login.by_email") }}</p>
          <a
           class="text-techno"
           :href="'mailto:' + $t('general.supportEmail')"
          >{{ $t("general.supportEmail") }}</a>
        </v-card>
      </v-menu>

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>

        <v-list style="min-width:250px;">
          <LanguageToggler />
        </v-list>
      </v-menu>
    </v-app-bar>

    <div id="login-content">
 
        <v-card id="login-card" elevation="2">
          <div class="row" style="margin:0px;">
            <div class="col-md-5 mobile-hide" style="padding:0px;">
              <transition name="fade" mode="out-in">
                <img v-if="imageIndex == 1" key="image1" src="/assets/img/Login_1.png" style="height:450px" />
                <img v-else-if="imageIndex == 2" key="image2" src="/assets/img/Login_2.png" style="height:450px" />
                <img v-else-if="imageIndex == 3" key="image3" src="/assets/img/Login_3.png" style="height:450px" />
              </transition>
            </div>
            <div id="login-right" class="col-md-7">
              <form @submit.prevent="handleLogin" style="text-align:center;">
                <v-card-title id="login-title">{{
                  $t("login.connect")
                }}</v-card-title>

                <v-text-field
                  :label="$t('login.username')"
                  name="username"
                  v-model="user.username"
                  v-validate="'required'"
                  type="text"
                  placeholder=" "
                  persistent-placeholder
                ></v-text-field>
                <v-text-field
                  :label="$t('login.password')"
                  name="password"
                  v-model="user.password"
                  v-validate="'required'"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder=" "
                  persistent-placeholder
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-alert
                  v-if="capsLockIsOn"
                  color="warning"
                  icon="$warning"
                  class="text-left"
                >{{ $t("login.capsLockIsOn") }}</v-alert>

                <p style="text-align: right; margin-right: 8px;"> <router-link to="/forgot-password" style="font-size: 11px;">{{ $t("login.forgotpwd") }}</router-link></p>

                <div>
                  <v-btn class="btn-techno" type="submit" elevation="2" rounded :disabled="!user.password">
                    <span v-if="loading">
                      <v-progress-circular
                        :size="25"
                        style="color:#fff;"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    <span v-else>
                      {{ $t("general.loginbutton") }}
                    </span>
                  </v-btn>
                </div>
                <br/>
                <span v-if="message" style="color:#ff4340;">{{ message }}</span>
              </form>
            </div>
          </div>
        </v-card>
    </div>
  </div>
</template>

<script>
import User from "@/models/user";
import LanguageToggler from '@/components/menus/LanguageToggler'

var loginImageInterval = null;

export default {
  name: "Login",
  components: {
    LanguageToggler
  },
  data() {
    return {
      showAbout: false,
      showPassword: false,
      user: new User("", ""),
      loading: false,
      message: "",
      imageIndex: 1,
      capsLockIsOn: false
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
    else {
      if (loginImageInterval == null) {
        loginImageInterval = setInterval(function(){
          this.imageIndex++;
          if (this.imageIndex > 3) {
            this.imageIndex = 1;
          }
        }.bind(this), 10000);
      }
    }
  },
  mounted() {
    // Aviser l'utilisateur du CapsLock
    let $this = this;
    document.querySelector('input[name="password"]').addEventListener('keyup',
      function (e) {
          if (typeof e.getModifierState === "function"){
              $this.capsLockIsOn = (e.getModifierState('CapsLock')?true:false);
          }
      });
  },
  methods: {
    logIn() {
      this.$router.push("/login");
    },
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          let data = { username: this.user.username, password: this.user.password, locale: this.$i18n.locale };
          this.user.password = '';

          this.$store.dispatch("auth/login", data).then(response => {

              this.$socket.emit('userId', { userId: response.id })

              const urlParams = new URLSearchParams(window.location.search);
              const myParam = urlParams.get('returnurl');
              
              if (myParam && myParam.startsWith('/')) {
                this.$router.push(myParam)
              }
              else {
                this.$router.push("/");
              }
            },
            (error) => {
              console.log(error.response)
              this.loading = false;
              
              if (error.response && error.response.data) {
                if (error.response.data.message) {
                  this.message = error.response.data.message;
                }
                else {
                  this.message = error.response.data;
                }
              }
              else if (error.message) {
                this.message = error.message;
              }
              else {
                this.message = error.toString();
              }
            }
          );
        }
      });
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.fade-enter-active {
  transition: opacity 1s;
}
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.05;
}
a {
  text-decoration: none;
}
</style>