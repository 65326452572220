import axios from 'axios';
import authHeader from './auth-header';

import constants from '../constants';
var API_URL = constants.API_URL + 'auth/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        username: user.username,
        password: user.password,
        locale: user.locale,
        isWeb: true
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
          document.cookie = "accessToken=" + response.data.accessToken + ";path=/";
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    document.cookie = 'accessToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password,
      locale: user.locale
    });
  }

  verifyEmail(email) {
    console.log(email)
    return axios
      .post(API_URL + 'verifyEmail', {
        email: email.email,
        locale: email.locale
      })
      .then(response => {
        return response.data;
      });
  }
  validateToken(info) {
    console.log(info)
    return axios
      .post(API_URL + 'validateToken', info, { headers: authHeader() })
      .then(response => {
        return response.data;
      })
  }

  receiveNewPassword(info) {
    console.log(info)
    return axios
      .post(API_URL + 'receiveNewPassword', {
        userId: info.userId,
        token: info.token,
        password: info.newPassword,
        locale: info.locale
      }, { headers: authHeader() })
      .then(response => {

        return response.data;
      })
  }
}

export default new AuthService();