import { getLanguage } from "@/utils/utils";
export default function authHeader() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.accessToken) {
      document.cookie = "accessToken=" + user.accessToken + ";path=/";
      return { 'x-access-token': user.accessToken, 'sio': window.__socketId, 'locale': getLanguage() };
    } else {
      return {};
    }
  }
