//F.R. 2021-01-08 Afficher les langues toujours dans leur langue d'origine
//import i18n from "@/i18n";

function languageList() {
    return [
        {
            text: 'English (United States)', //i18n.t('languages.enUS')
            value: "enUS"
        },
        {
            text: 'English (Canada)', //i18n.t('languages.enCA')
            value: "enCA"
        },
        {
            text: 'English (United Kingdom)', //i18n.t('languages.enGB')
            value: "enGB"
        },
        {
            text: 'Français (France)', //i18n.t('languages.frFR')
            value: "frFR"
        },
        {
            text: 'Français (Canada)', //i18n.t('languages.frCA')
            value: "frCA"
        },
        {
            text: 'Français (Belgique)', //i18n.t('languages.frBE')
            value: "frBE"
        },
    ]
}

export {
    languageList,
}