<template>
  <v-app-bar absolute id="app-bar" v-bind:style="{ 'background-color': (isDevelopment?'#f00':'#fff') }">
    <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>

    <v-toolbar-title v-if="!isMobile"> <router-link to="/">
      <img v-if="!isMobile"
      :src="'/assets/svg/'+ $t('general.company_name') + '.svg'"
      height="40"
      id="appbar-logo"
    /></router-link></v-toolbar-title>
    <h1 v-if="!isMobile" id="h1-title">{{$t("general.app_name")}}</h1>
    <v-alert
      v-if="0 === 1"
      border="top"
      type="info"
      color="red lighten-2"
      style="margin-left:20px;"
      dark
    >
    {{$t('general.msg_info')}}
    </v-alert>
    <v-alert
      v-if="!isMobile && msgReleaseUntil('2024-10-03')"
      border="top"
      type="success"
      color="red lighten-2"
      style="margin-left:20px;"
      dark
    >
      <a
        style="color:#fff"
        target="_blank"
        :href="$t('general.msg_release_link')"
        >{{ $t("general.msg_release", {appVersion:simplifyAppVersion(appVersion)}) }}</a>
    </v-alert>
    
    <v-alert
      v-if="isDevelopment"
      border="top"
      type="info"
      color="red lighten-2"
      style="margin-left:20px;"
      dark
    >
      <div v-html="gitBranch"></div>
    </v-alert>

    <v-spacer></v-spacer>

    <v-btn icon>
      <router-link to="/mobile-apps" style="text-decoration:none;"><v-icon style="color:rgba(0, 0, 0, 0.54);">mdi-cellphone-arrow-down</v-icon></router-link>
    </v-btn>

    <v-menu
      class="space-btn"
      v-model="showAbout"
      :close-on-content-click="false"
      :nudge-width="0"
      attach="#app-bar"
      offset-y
      left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-help</v-icon>
        </v-btn>
      </template>

      <v-card style="padding:10px; border-radius:8px;">
        <h2>{{ $t("login.about") + " - " + $t("general.app_name")}}</h2>

        <v-divider></v-divider>
        <span>Version</span>
        {{ appVersion }}<br/><br/>

        <span>{{ $t("login.by_phone") }}</span>
        <br />
        <a class="text-techno" href="tel:418-755-0294">418-755-0294</a>
        <br />

        <span>{{ $t("login.by_email") }}</span>
        <br />
        <a
          class="text-techno"
          :href="'mailto:' + $t('general.supportEmail')"
          >{{ $t("general.supportEmail") }}</a>
        <br />
        <span>{{ $t("login.license_agreement") }}</span>
        <br />
        <a class="text-techno" @click="showContractDialog = true;">
        <ContractDialog
          v-model="showContractDialog"

          :title="$t('contract.head')"
          :description="$t('contract.contract_file_name')"
          
        />
        {{ $t("login.view_agreement") }}
        </a>
      </v-card>
    </v-menu>
    
    <NotificationsBar />

    <v-menu 
      attach="#app-bar" 
      :close-on-content-click="false"
      :nudge-width="0" 
      offset-y
      left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" id="avatar-btn" class="space-btn" :style="{ background: currentUser && currentUser.colorCode ? currentUser.colorCode : 'gray' }">
          <h2 v-if="currentUser">{{ currentUser.username[0].toUpperCase() }}</h2>
        </v-btn>
      </template>

      <v-list style="min-width:250px;">
        <LanguageToggler
          @languageChanged="reload()"
        />
        
        <v-list-item @click="goToProfile">
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-content>{{ $t("general.profile") }}</v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item @click="logOut">
          <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
          <v-list-item-content>{{ $t("general.logout") }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>


<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import LanguageToggler from '@/components/menus/LanguageToggler'
import ContractDialog from "@/components/dialogs/ContractDialog"
import NotificationsBar from "@/components/NotificationsBar"


export default {
  components: {
    LanguageToggler,
    ContractDialog,
    NotificationsBar
  },
  computed: {
    ...mapState({
       currentUser: state => state.currentUser,
       isMobile: state => state.isMobile,
       drawer: (state) => state.drawer,
    })
  },
  methods: {
    ...mapActions({
    }),
    ...mapMutations([
      'SET_NAV'
    ]),
    toggleDrawer() {
      this.SET_NAV(!this.drawer);
    },
    goToProfile: function() {
      this.$router.push("/user/" + this.currentUser.id);
    },
    logOut() {
      this.$store.dispatch("logout", this.$socket);
    },
    reload() {
      localStorage.removeItem("_countries");
      location.reload();
    },
    msgReleaseUntil(start_date){
      return ((new Date().getTime())<(new Date(start_date).getTime()))
    },
    simplifyAppVersion(versionString){
      const regex = /^(\d+\.)?(\d+\.)?(\*|\d+)/;
      const match = versionString.match(regex);
      let version, release = ''; // commit
      if (match) {
        [version, release] = match.slice(1); // ,commit
      }
      return version + release.replace('.','');
    }
  },
  data() {
    return {
      showAbout: false,
      showNotif: false,
      showContractDialog: false,
      appVersion:process.env.VUE_APP_VERSION,
      isDevelopment: (process.env.VUE_APP_GIT_BRANCH !== "master" && process.env.VUE_APP_GIT_BRANCH !== ""),
      gitBranch: 'Branch: ' + process.env.VUE_APP_GIT_BRANCH.replace(/ UTC #((\w{3,10})(\[\w+\])?)/i," UTC <a href=\"https://app.clickup.com/t/$1\" style=\"color: #fff;font-weight: bold;\" target=\"_blank\">#$1</a>")
    };
  }
};
</script>
