import axios from "axios";
import authHeader from "./auth-header";
import { parseError, buildParamsUrl } from '@/utils/utils'
import constants from "../constants";

var API_URL = constants.API_URL + "user/";

var AUTH_API_URL = constants.API_URL + "auth/";
var DEALERS_API_URL = constants.API_URL + "dealers/";
var ROLES_API_URL = constants.API_URL + "roles/";
var PROJECTTYPES_API_URL = constants.API_URL + "projecttypes/";
var SUPPORTINGPLATES_API_URL = constants.API_URL + "supportingplates/";
var GROUPS_API_URL = constants.API_URL + "groups/";
var CUSTOMERS_API_URL = constants.API_URL + "customers/";

class UserService {
  getWebData(language, includeCountries) {
    return axios.get(API_URL + "webData?language=" + language + "&includeCountries=" + includeCountries, {
      headers: authHeader(),
    });
  }
  getReports(language, statusEnum) {
    return axios.get(API_URL + `webReports/${statusEnum}?language=` + language, { headers: authHeader() });
  }
  getProjects(language) {
    return axios.get(API_URL + "projects?language=" + language, { headers: authHeader() });
  }
  getTranslations(language) {
    return axios.get(API_URL + "translations?language=" + language, {
      headers: authHeader(),
    });
  }
  getReportData(reportId, language) {
    return axios.get(
      API_URL + "reportData/" + reportId + "?language=" + language,
      { headers: authHeader() }
    );
  }
  getProjectFiles(projectId) {
    return axios.get(API_URL + "projectFiles/" + projectId, { headers: authHeader() });
  }
  getProjectData(projectId, language, params) {
    return axios.get(API_URL + "projectData/" + projectId + "?language=" + language + buildParamsUrl(params, false), { headers: authHeader() });
  }
  getComments(relatableType, relatableId) {
    return axios.get(API_URL + "comments/" + relatableType + "/" + relatableId, { headers: authHeader() });
  }
  addComment(relatableType, relatableId) {
    return axios.post(API_URL + "comments", { relatableType: relatableType, relatableId: relatableId }, { headers: authHeader() });
  }
  updateComment(id, comment) {
    return axios.post(API_URL + "comments", { id: id, comment: comment }, { headers: authHeader() });
  }
  deleteComment(id) {
    return axios.post(API_URL + "comments", { id: id, isDelete: true }, { headers: authHeader() });
  }
  getUsers(language) {
    return axios.get(API_URL + "users?language=" + language, { headers: authHeader() });
  }
  getDealers(language) {
    return axios.get(DEALERS_API_URL + "?language=" + language, { headers: authHeader() });
  }
  getListMachines(language) {
    return axios.get(API_URL + "listMachines?language=" + language, { headers: authHeader() });
  }

  getRoles() {
    return axios.get(ROLES_API_URL, { headers: authHeader() });
  }
  saveRoles(data, language) {
    return axios.post(ROLES_API_URL + "?language=" + language, data, { headers: authHeader() });
  }
  saveRoleTranslation(data, language) {
    return axios.post(ROLES_API_URL + "?language=" + language, data, { headers: authHeader() });
  }
  addRole(data, language) {
    return axios.put(ROLES_API_URL + "?language=" + language, data, { headers: authHeader() });
  }
  removeRole(role, language) {
    return axios.delete(ROLES_API_URL + "?language=" + language + "&roleId=" + role.id, { headers: authHeader() });
  }
  requestInstallerCard(user, requestedStatus) {
    return axios.post(API_URL + 'requestInstallerCard', { userId: user.id, requestedStatus: requestedStatus }, { headers: authHeader() });
  }
  requestChangeStatus(user, changedStatus) {
    return axios.post(API_URL + 'requestChangeStatus', { userId: user.id, changedStatus: changedStatus }, { headers: authHeader() });
  }
  getDealerGroups() {
    return axios.get(GROUPS_API_URL, {headers: authHeader() });
  }
  addDealerGroup(data) {
    return axios.put(GROUPS_API_URL, data, {headers: authHeader() });
  }
  saveDealerGroup(data) {
    return axios.post(GROUPS_API_URL, data, { headers: authHeader() });
  }
  deleteDealerGroup(dealerGroup) {
    return axios.delete(GROUPS_API_URL + "?dealerGroupId=" + dealerGroup.id, { headers: authHeader() });
  }
  getSupportingPlates() {
    return axios.get(SUPPORTINGPLATES_API_URL, { headers: authHeader() });
  }
  addSupportingPlate(data) {
    return axios.put(SUPPORTINGPLATES_API_URL, data, {headers: authHeader() });
  }
  saveSupportingPlate(data) {
    return axios.post(SUPPORTINGPLATES_API_URL, data, { headers: authHeader() });
  } 
  deleteSupportingPlate(supportingplate) {
    return axios.delete(SUPPORTINGPLATES_API_URL + "?supportingPlateId=" + supportingplate.id, { headers: authHeader() });
  }
  saveSupportingPlateTranslation(data) {
    return axios.post(SUPPORTINGPLATES_API_URL, data, { headers: authHeader() });
  }
  
  getProjectTypes() {
    return axios.get(PROJECTTYPES_API_URL, { headers: authHeader() });
  }
  addProjectType(data) {
    return axios.put(PROJECTTYPES_API_URL, data, { headers: authHeader() });
  }
  saveProjectType(data) {
    return axios.post(PROJECTTYPES_API_URL, data, { headers: authHeader() });
  } 
  deleteProjectType(projecttype) {
    return axios.delete(PROJECTTYPES_API_URL + "?projectTypeId=" + projecttype.id, { headers: authHeader() });
  }
  saveProjectTypeTranslation(data) {
    return axios.post(PROJECTTYPES_API_URL, data, { headers: authHeader() });
  }
  addDealerMachine(data) {
    return axios.put(DEALERS_API_URL + 'dealerMachine', data, { headers: authHeader() });
  }
  deleteDealerMachine(dealerMachine) {
    return axios.delete(DEALERS_API_URL + "dealerMachine?dealerMachineId=" + dealerMachine.id, { headers: authHeader() });
  }
  saveDealerMachine(data, language) {
    return axios.post(DEALERS_API_URL + 'dealerMachine?language=' + language, data, { headers: authHeader() });
  }
  async sendCorrelationTableEmail(formData) {
    try {
      let headers = authHeader();
      headers['Content-Type'] = 'multipart/form-data';
      let response = await axios.post(DEALERS_API_URL + 'sendCorrelationTableEmail', formData, { headers: headers });
      return { success: true, data: response.data } ;
    }
    catch (err) {
      return { success: false, data: parseError(err) };
    }
  }
  
  getCustomers(language) {
    return axios.get(API_URL + "customers?language=" + language, { headers: authHeader() });
  }
  async createCustomer(data) {
    try {
      let response = await axios.put(CUSTOMERS_API_URL, data, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return parseError(error);
    }
  }
  
  async saveCustomerChanges(customer) {
    try {
      let response = await axios.post(
        API_URL + "saveCustomerChanges",
        { customerId: customer.id, changes: customer.changes },
        { headers: authHeader() }
      );
      return response;
    } catch (error) {
      console.log(error);
      return parseError(error);
    }
  }

  deleteCustomer(customerId) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .delete(
            CUSTOMERS_API_URL + "?customerId=" + customerId,
            { headers: authHeader() }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getCustomerPageData(customerId, language) {
    return axios.get(API_URL + 'customerPageData/' + customerId + '?language=' + language, { headers: authHeader() });
  }
  
  getUserPageData(userId, language) {
    return axios.get(
      API_URL + "userPageData/" + userId + "?language=" + language,
      { headers: authHeader() }
    );
  }

  async sendNewAccountEmail(user, language) {
    try {
      let response = await axios.post(
        AUTH_API_URL + "sendNewAccountEmail",
        { userId: user.id, language: language },
        { headers: authHeader() }
      );
      return response;
    } catch (error) {
      console.log(error);
      return parseError(error);
    }
  }

  async saveUserChanges(user) {
    try {
      let response = await axios.post(
        API_URL + "saveUserChanges",
        { userId: user.id, changes: user.changes, locale: user.locale },
        { headers: authHeader() }
      );
      return response;
    } catch (error) {
      console.log(error);
      return parseError(error);
    }
  }

  async createUser(data) {
    try {
      let response = await axios.post(API_URL + "createUser", data, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return parseError(error);
    }
  }

  async checkUser(data) {
    try {
      let response = await axios.post(
        API_URL + "checkUser",
        data, 
        { headers: authHeader() },
      );

      return response;
    } catch (error) {
      return parseError(error);
    }
  }

  async saveDealerChanges(dealer) {
    try {
      let response = await axios.post(
        DEALERS_API_URL,
        { dealerId: dealer.id, changes: dealer.changes },
        { headers: authHeader() }
      );
      return response;
    } catch (error) {
      console.log(error);
      return parseError(error);
    }
  }

  async createDealer(data) {
    try {
      let response = axios.put(DEALERS_API_URL, data, {
        headers: authHeader(),
      });
      return response;
    } catch (error) {
      return parseError(error);
    }
  }
}

export default new UserService();
