import i18n from "@/i18n";

let emailRules = [
    (v) => v && !!v.trim() || i18n.t("customer.username_valid"),
    (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || i18n.t("customer.email_valid"),
    (v) => !!v && v.length <= 255 || i18n.t("customer.email_length"),
]

let emailsRules = [ // Accepte les ; pour inscrire 2 courriels différents
    (v) => v && !!v.trim() || i18n.t("customer.username_valid"),
    (v) => /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(v) || i18n.t("customer.email_valid"),
    (v) => !!v && v.length <= 255 || i18n.t("customer.email_length"),
]

function isTechnoPieuxEmail(email) {
    const regex = new RegExp(/.*@(technopieux|technometalpost)\.(com|pl|fr|be|es|ca|ch|co\.uk|pl)$/, 'gmi');
    return email && regex.test(email);
}

const capitalizeFirstLetter = function(str) {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const yyyymmdd = function(date) {
    date = new Date(date);

    if(!date)
        return i18n.t('utils.never')

    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth()+1).toString(); // getMonth() is zero-based
    var dd  = date.getDate().toString();
    return yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]);
}

const yyyymmddhhmm = function(date) {
    date = new Date(date);

    if(!date)
        return i18n.t('utils.never')

    var yyyy = date.getFullYear().toString();
    var mm = (date.getMonth()+1).toString(); // getMonth() is zero-based
    var dd  = date.getDate().toString();

    var hh = date.getHours().toString();
    var minutes = date.getMinutes().toString();
    minutes = minutes[1] ? minutes : "0" + minutes[0];

    return yyyy + '-' + (mm[1]?mm:"0"+mm[0]) + '-' + (dd[1]?dd:"0"+dd[0]) + " " + i18n.t('utils.at') + " " + hh + "h" + minutes;
}

export {
    emailRules, emailsRules, capitalizeFirstLetter, yyyymmdd, yyyymmddhhmm, isTechnoPieuxEmail
}