<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    version="1.1"
    :class="{ 'techno-icon': active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M32.617,61.924 C32.99,62.078 33.42,61.992 33.707,61.707 L37,58.414 L40.293,61.707 C40.484,61.898 40.74,62 41,62 C41.129,62 41.259,61.975 41.383,61.924 C41.756,61.769 42,61.404 42,61 L42,47.89 C43.235,46.627 44,44.902 44,43 C44,39.14 40.86,36 37,36 C33.14,36 30,39.14 30,43 C30,44.902 30.765,46.627 32,47.89 L32,61 C32,61.404 32.244,61.769 32.617,61.924 L32.617,61.924 Z M37.707,56.293 C37.316,55.902 36.684,55.902 36.293,56.293 L34,58.586 L34,49.315 C34.911,49.75 35.925,50 37,50 C38.075,50 39.089,49.75 40,49.315 L40,58.586 L37.707,56.293 L37.707,56.293 Z M37,38 C39.757,38 42,40.243 42,43 C42,45.757 39.757,48 37,48 C34.243,48 32,45.757 32,43 C32,40.243 34.243,38 37,38 L37,38 Z"
      id="Shape"
    ></path>
    <path
      d="M16,52 L29,52 C29.552,52 30,51.552 30,51 C30,50.448 29.552,50 29,50 L16,50 C14.897,50 14,49.103 14,48 L14,6 C14,4.897 14.897,4 16,4 L36,4 L36,12 C36,14.206 37.794,16 40,16 L48,16 L48,48 C48,49.103 47.103,50 46,50 L45,50 C44.448,50 44,50.448 44,51 C44,51.552 44.448,52 45,52 L46,52 C48.206,52 50,50.206 50,48 L50,14 C50,13.735 49.895,13.48 49.707,13.293 L38.707,2.293 C38.52,2.105 38.265,2 38,2 L16,2 C13.794,2 12,3.794 12,6 L12,48 C12,50.206 13.794,52 16,52 L16,52 Z M47.586,14 L40,14 C38.897,14 38,13.103 38,12 L38,4.414 L47.586,14 L47.586,14 Z"
      id="Shape"
    ></path>
    <path
      d="M19,20 L29,20 C29.552,20 30,19.552 30,19 C30,18.448 29.552,18 29,18 L19,18 C18.448,18 18,18.448 18,19 C18,19.552 18.448,20 19,20 L19,20 Z"
      id="Shape"
    ></path>
    <path
      d="M19,26 L43,26 C43.552,26 44,25.552 44,25 C44,24.448 43.552,24 43,24 L19,24 C18.448,24 18,24.448 18,25 C18,25.552 18.448,26 19,26 L19,26 Z"
      id="Shape"
    ></path>
    <path
      d="M19,32 L43,32 C43.552,32 44,31.552 44,31 C44,30.448 43.552,30 43,30 L19,30 C18.448,30 18,30.448 18,31 C18,31.552 18.448,32 19,32 L19,32 Z"
      id="Shape"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "64px",
    },
    height: {
      type: String,
      default: "64px",
    },
  },
};
</script>

<style scoped>
.techno-icon {
  fill: #009f4d !important;
}
</style>