<template>
  <center>
     <img
     :src="'/assets/svg/'+ $t('general.company_name') + '.svg'"
      height="40"
    />
    <img src="/assets/img/404-whoops.svg" style="display:block;" width="300" />
   
    <br />
    <h1>{{$t('page404.title')}}</h1>
    <br />
    <h3>{{$t('page404.message')}}</h3>
  </center>
</template>

<script>

  export default {
    name: 'NotFound'
  }

</script>

<style scoped>

  center {
    margin: 10vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }
</style>