<template>
  <div style="height:100%;background:#F1F1F1;">
    <v-app-bar absolute style="background:white;">
      <router-link to="/"><img :src="'/assets/svg/'+ $t('general.company_name') + '.svg'" id="login-logo" /></router-link>

      <v-toolbar-title></v-toolbar-title>
      <h1 class="hidden-sm-and-down" id="h1-title">{{$t("general.app_name")}}</h1>
      <v-spacer></v-spacer>

      <v-menu v-model="showAbout" :close-on-content-click="false" :nudge-width="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>

        <v-card style="padding:10px; border-radius:8px;">
          <h2>{{ $t("login.about") }}</h2>
          <v-divider></v-divider>
          <p position="stacked" style="margin-bottom: 0px;">{{ $t("login.by_phone") }}</p>
          <a class="text-techno" href="tel:418-755-0294">418-755-0294</a>

          <p style="margin-top:10px;margin-bottom:0px;">{{ $t("login.by_email") }}</p>
          <a
           class="text-techno"
           :href="'mailto:' + $t('general.supportEmail')"
          >{{ $t("general.supportEmail") }}</a>
        </v-card>
      </v-menu>

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>

        <v-list style="min-width:250px;">
          <LanguageToggler />
        </v-list>
      </v-menu>
    </v-app-bar>

    <div id="login-content">
 
        <v-card id="login-card" elevation="2">

          <div v-if="expiredToken" style="text-align: center;">
            <div style="position: absolute;top: 50%;transform: translate(0%, -50%);width:100%;">
              <span style="display:block;font-size:22px;">{{ $t("email.expired_token") }}</span>
              <span style="display:block;font-size:20px">
                <router-link to="/forgot-password">{{ $t("email.expired_token_redirect") }}</router-link>
              </span>
            </div>
            
          </div>
          <v-form v-else ref="form" @submit.prevent="onSubmit" style="text-align:center;" v-model="isValid" lazy-validation>
            <fieldset style="border:none;padding: 100px 25px 0px 25px;">
               <v-card-title id="login-title">{{
                  $t("email.new_password")
                }}</v-card-title>
            
                <v-text-field
                  :label="$t('email.new_password')"
                  name="newPassword"
                  v-model="newPassword"
                  :rules="[rules.required, rules.password]"
                  style="margin: 25px 0px;"
                  :type="showPassword1 ? 'text' : 'password'"
                  @change="validate"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                ></v-text-field>

                <v-text-field
                  :label="$t('email.confirm_pwd')"
                  name="confirmPwd"
                  v-model="confirmPwd"
                  :rules="[rules.required, rules.password]"
                  style="margin: 25px 0px;"
                  :type="showPassword2 ? 'text' : 'password'"
                  @change="validate"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                ></v-text-field>
                <v-alert
                  v-if="capsLockIsOn"
                  color="warning"
                  icon="$warning"
                  class="text-left"
                >{{ $t("login.capsLockIsOn") }}</v-alert>
               <div>
                  <v-btn id="btn-submit" class="btn-techno" type="submit" elevation="2" rounded :disabled="!newPassword || !confirmPwd || !isValid">
                    <span v-if="loading">
                      <v-progress-circular
                        :size="25"
                        style="color:#fff;"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    <span v-else>
                      {{ $t("email.submit") }}
                    </span>
                  </v-btn>
                </div>
                <br/>
                <span v-if="message" style="color:#ff4340;">{{ message }}</span>
            </fieldset>
          </v-form>
        </v-card>

        <v-snackbar v-model="showConfirmation" timeout="-1">
          {{ $t("email.password_changed") }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="green"
              text
              v-bind="attrs"
              @click="showConfirmation = false"
            >
              {{ $t("general.close") }}
            </v-btn>
          </template>
        </v-snackbar>
    </div>
  </div>
</template>

<script>
import LanguageToggler from '@/components/menus/LanguageToggler';

export default {
  name: "ForgotPassword",
  components: {
    LanguageToggler,
  },
  data() {
    return {
      showAbout: false,
      locale: this.$i18n.locale,
      showPassword1: false,
      showPassword2: false,
      newPassword: '',
      confirmPwd: '',
      loading: false,
      message: '',
      showConfirmation: false,
      isValid: true,
      expiredToken: false,
      capsLockIsOn: false,
      rules: {
        password: value => {
            const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
            return pattern.test(value) || this.$t("validation.password")
         },
        required: value => !!value || this.$t("validation.required"),
      }
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
    else {
      var scope = this;
      let data = { userId: this.$route.params.id, token: this.$route.params.token, locale: this.locale };
      this.$store.dispatch("auth/validateToken", data).then(
        (response) => {
          // Token is OK
          console.log(response)
        },
        (error) => {
          console.log(error)
          scope.expiredToken = true;
        }
      );
    }
  },
  mounted() {
    // Aviser l'utilisateur du CapsLock
    let $this = this;
    document.querySelectorAll('input[name="newPassword"], input[name="confirmPwd"]').forEach(el => {
        el.addEventListener('keyup', function (e) {
                                      $this.capsLockIsOn = (e.getModifierState('CapsLock')?true:false);
                                     }
                           )
      });
  },
  methods: {
    logIn() {
      this.$router.push("/login");
    },
    validate() {
      this.$refs.form.validate()
    },
    onSubmit: function() {

      let scope = this
      let data = { newPassword: this.newPassword, confirmPwd: this.confirmPwd, locale: this.locale }

      if (data.newPassword != data.confirmPwd) {
        this.message = this.$t('login.passwords_dont_match');
      }
      else if(!this.isValid){
        this.message = this.$t('validation.password');
      }
      else {
        data.userId = this.$route.params.id;
        data.token = this.$route.params.token;
        data.locale = this.locale;
        this.$store.dispatch("auth/receiveNewPassword", data).then(
          () => {
            this.message = '';
            this.loading = false;

            this.showConfirmation = true;

            setTimeout(() => {
              scope.newPassword = '';
              scope.confirmPwd = '';
              scope.$router.push('/login')
            }, 3000)
          },
          (error) => {
            this.loading = false;

            if (error.response && error.response.data) {
              if (error.response.data.message) {
                this.message = error.response.data.message;
              }
              else {
                this.message = error.response.data;
              }
            }
            else if (error.message) {
              this.message = error.message;
            }
            else {
              this.message = error.toString();
            }
          }
        );
      }
      
    }
  }
};
</script>

<style scoped>

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
a {
  text-decoration: none;
}
</style>