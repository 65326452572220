export default {
    SET_IS_MOBILE(state, value) {
      state.isMobile = value;
    },
    SET_NAV(state, value) {
      state.drawer = value;
    },
    SET_BREADCRUMBS(state, value) {
      state.breadcrumbs = value;
    },
    SET_AVAILABLE_LANGUAGES(state, languages) {
      state.availableLanguages = languages;
    },
    openToast(state, { message, duration, color }) {
      state.toastMessage = message;
      state.toastButtonColor = color;
      state.showToast = true;
      state.toastTimeout = setTimeout(() => {
        state.showToast = false;
      }, duration);
    },
    closeToast(state) {
      clearTimeout(state.toastTimeout);
      state.showToast = false;
    },

    changeDataInitializing(state, value) {
      state.isInitializingData = value;
    },
    changeDataInitialized(state, value) {
      state.isDataInitialized = value;
    },

    setCurrentUser(state, value) {
      state.currentUser = value;
      state.currentUser.fullname = value.firstname + ' ' + value.lastname;
      //console.log('setCurrentUser', state.currentUser)
    },

    setBadgeNumber(state, badgeNumber) {
      state.badgeNumber = badgeNumber;
    },

    setUserFunctions(state, userFunctions) {
      state.userFunctions = userFunctions;
      //console.log('setUserFunctions', userFunctions)
    },

    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },

    setCountries(state, countries) {
      state.countries = countries;
      //console.log('setCountries', countries)
    },

    updateUserAutoAddInstaller(state, value) {
      if (state.currentUser) {
        state.currentUser.isAutoAddedInstaller = value;
      }
    },

    setSocket(state, socket) {
      state.socket = socket;
    },

    showErrorMessage(state, error) {
      state.toastMessage = error;
      state.toastButtonColor = "red";
      state.showToast = true;
      state.toastTimeout = setTimeout(() => {
        state.showToast = false;
      }, 10000);
    },
}
