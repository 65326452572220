import UserService from "../services/user.service";

import router from "../router/index.js";

import { parseError, getLanguage } from '@/utils/utils'

import { yyyymmdd } from "../utils/validation";

export default {
    setIsMobile({ commit }, value) {
      commit("SET_IS_MOBILE", value);

      // On affiche la barre des modules si on n'est pas en mode mobile
      commit('SET_NAV', !value);
    },
    setBreadcrumbs({ commit }, value) {
      commit("SET_BREADCRUMBS", value);
    },
    initAppData: async ({commit, dispatch, state}) => {  // eslint-disable-line
     
      if (!state.isDataInitialized && !state.isInitializingData) {
        console.log("INITIALIZING !");
        commit("changeDataInitializing", true);

        // On load les pays du localStorage
        // Si présent, on ne redemande pas les pays au serveur
        let includeCountries = true;
        let countries = localStorage.getItem("_countries");
        if (countries) {
          countries = JSON.parse(countries);
          commit('setCountries', countries)
          includeCountries = false;
        }

        // On load les fonctions du localStorage pour accélérer le UI à l'ouverture de l'application
        // Les fontions seront mis à jour à la réception des données usagers
        let userFunctions = localStorage.getItem("user_functions");
        if (userFunctions) {
          userFunctions = JSON.parse(userFunctions);
          commit("setUserFunctions", userFunctions);
        }
        
        UserService.getWebData(getLanguage(), includeCountries)
          .then(function(response) {
            console.log('Retrieved web data from server', response.data)

            commit("setCurrentUser", response.data.user);

            commit("setBadgeNumber", response.data.notification.badgeNumber);

            commit("setUserFunctions", response.data.userFunctions);
            localStorage.setItem("user_functions", JSON.stringify(response.data.userFunctions));

            commit("setUserRoles", response.data.userRoles);

            // On sauvegarde les pays dans le localStorage, cela ne changera pas souvent
            if (response.data.countries) {
              commit("setCountries", response.data.countries)
              localStorage.setItem("_countries", JSON.stringify(response.data.countries));
            }
            commit("changeDataInitialized", true);
          })
          .catch(function(error) {
            console.log(error)
            let errorMsg = parseError(error);
            commit("showErrorMessage", errorMsg);

            if (error.toString().includes("401")) {
              dispatch("logout", state.socket);
            }
          })
          .finally(function() {
            commit("changeDataInitializing", false);
          });
      } else {
        if (state.isDataInitialized) {
          //console.log("ALREADY INITIALIZED...");
        } else {
          console.log("ALREADY INITIALIZING...");
        }
      }
    },
    
    logout: ({commit, dispatch}, socket) => {
      console.log('LOGOUT')
      dispatch('auth/logout');
      commit("changeDataInitializing", false);
      commit('changeDataInitialized', false);

      // On envoie le nouvel id lié au socket au serveur
      if (socket) {
        socket.emit('userId', { userId: null })
      }

      // On efface le localStorage utilisé pour accélérer le UI
      localStorage.removeItem("user_functions");
      localStorage.removeItem("_countries");

      router.push('/login');
    },
    
    yyyymmdd: yyyymmdd
  }