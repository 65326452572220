<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    version="1.1"
    :class="{ 'techno-icon': active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M44,21 C44,13.832 38.168,8 31,8 C23.832,8 18,13.832 18,21 C18,26.12 20.983,30.546 25.297,32.664 C14.252,35.252 6,45.177 6,57 C6,57.553 6.448,58 7,58 C7.552,58 8,57.553 8,57 C8,44.317 18.318,34 31,34 C43.682,34 54,44.317 54,57 C54,57.553 54.448,58 55,58 C55.552,58 56,57.553 56,57 C56,45.177 47.748,35.252 36.703,32.664 C41.017,30.546 44,26.12 44,21 L44,21 Z M31,32 C24.935,32 20,27.065 20,21 C20,14.935 24.935,10 31,10 C37.065,10 42,14.935 42,21 C42,27.065 37.065,32 31,32 L31,32 Z"
      id="Shape"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "64px",
    },
    height: {
      type: String,
      default: "64px",
    },
  },
};
</script>

<style scoped>
.techno-icon {
  fill: #009f4d !important;
}
</style>