import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.css';

Vue.use(Vuetify);

import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

const vuetify = new Vuetify({
    breakpoint: {
      mobileBreakpoint: 'sm' // This is equivalent to a value of 960. Default is 'md' with 1280
    },
    lang: {
      locales: { fr, en },
      current: 'en',
    },
    theme: {
      dark:false,
      themes: {
        light: {
          primary: '#009f4d',
        },
      },
    },
})
  

export default vuetify