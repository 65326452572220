<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    version="1.1"
    :class="{ 'techno-icon': active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M5.707,33.707 L32,7.414 L58.293,33.707 C58.488,33.902 58.744,34 59,34 C59.256,34 59.512,33.902 59.707,33.707 C60.098,33.316 60.098,32.684 59.707,32.293 L32.707,5.293 C32.316,4.902 31.684,4.902 31.293,5.293 L4.293,32.293 C3.902,32.684 3.902,33.316 4.293,33.707 C4.684,34.098 5.316,34.098 5.707,33.707 L5.707,33.707 Z"
      id="Shape"
    ></path>
    <path
      d="M37,60 L55,60 C55.553,60 56,59.552 56,59 L56,34 C56,33.448 55.553,33 55,33 C54.447,33 54,33.448 54,34 L54,58 L38,58 L38,43 C38,42.448 37.553,42 37,42 L27,42 C26.447,42 26,42.448 26,43 L26,58 L10,58 L10,34 C10,33.448 9.553,33 9,33 C8.447,33 8,33.448 8,34 L8,59 C8,59.552 8.447,60 9,60 L27,60 C27.553,60 28,59.552 28,59 L28,44 L36,44 L36,59 C36,59.552 36.447,60 37,60 L37,60 Z"
      id="Shape"
    ></path>
    <path
      d="M47,17 C47.553,17 48,16.552 48,16 L48,12 L54,12 L54,24 C54,24.552 54.447,25 55,25 C55.553,25 56,24.552 56,24 L56,11 C56,10.448 55.553,10 55,10 L47,10 C46.447,10 46,10.448 46,11 L46,16 C46,16.552 46.447,17 47,17 L47,17 Z"
      id="Shape"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "64px",
    },
    height: {
      type: String,
      default: "64px",
    },
  },
};
</script>

<style scoped>
.techno-icon {
  fill: #009f4d !important;
}
</style>