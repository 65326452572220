<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    version="1.1"
    :class="{ 'techno-icon': active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M30.134,59.5 C30.312,59.81 30.643,60 31,60 C31.357,60 31.688,59.81 31.866,59.5 L47.416,32.572 C49.106,29.678 50,26.369 50,23 C50,12.523 41.477,4 31,4 C20.523,4 12,12.523 12,23 C12,26.368 12.893,29.677 14.583,32.569 L30.134,59.5 L30.134,59.5 Z M31,6 C40.374,6 48,13.626 48,23 C48,26.015 47.201,28.976 45.688,31.564 C45.687,31.565 45.686,31.567 45.685,31.568 L31,57 L16.31,31.561 C14.799,28.975 14,26.014 14,23 C14,13.626 21.626,6 31,6 L31,6 Z"
      id="Shape"
    ></path>
    <path
      d="M31,32 C35.962,32 40,27.962 40,23 C40,18.038 35.962,14 31,14 C26.038,14 22,18.038 22,23 C22,27.962 26.038,32 31,32 L31,32 Z M31,16 C34.86,16 38,19.14 38,23 C38,26.86 34.86,30 31,30 C27.14,30 24,26.86 24,23 C24,19.14 27.14,16 31,16 L31,16 Z"
      id="Shape"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "64px",
    },
    height: {
      type: String,
      default: "64px",
    },
  },
};
</script>

<style scoped>
.techno-icon {
  fill: #009f4d !important;
}
</style>