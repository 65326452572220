import axios from 'axios';
import authHeader from './auth-header';

import constants from '../constants';
import { parseError } from '@/utils/utils'
var API_URL = constants.API_URL + 'notification/';

class NotificationService {

  async get(start, nb) {
    try {
      let response = await axios.get(API_URL + `${start}/${nb}`, { headers: authHeader() });
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, data: parseError(error) };
    }
  }

  async getConfig() {
    let result = await axios.get(API_URL + 'config', { headers: authHeader() });
    if (result.status == 200 && result.data?.data) return result.data.data;
    return false;
  }

  async setConfig(item, changedValue) {
    try {
      await axios.post(API_URL + 'config', {id: item.id, field: changedValue, value: item[changedValue]}, { headers: authHeader() });
      return true;
    }
    catch(e) {
      return e.response?.data?.message || e.message;
    }
  }

  async markAsRead(item, all, items) {
    let req;
    if (item?.id == null) return console.error("Mark all ads read: no item provided");
    if (all === true) req = {all: true, upToId: item.id};
    else req = {id: item.id}
    try {
      await axios.post(API_URL + 'markAsRead', req, { headers: authHeader() });
      if (all === true) {
        for (let c of (items || [])) {
          c.read = 1;
        }
      }
      else {
        item.read = 1;
      }
      return true;
    }
    catch(e) {
      return e.response?.data?.message || e.message;
    }
  }

}

export default new NotificationService();