<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 64 64"
    version="1.1"
    :class="{ 'techno-icon': active }"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M42,26 C42,20.486 37.514,16 32,16 C26.486,16 22,20.486 22,26 C22,29.694 24.019,32.919 27.006,34.651 C18.732,36.779 12.488,44.045 12.027,52.948 C11.998,53.5 12.422,53.969 12.974,53.999 C13.523,54.031 13.996,53.603 14.024,53.052 C14.521,43.49 22.416,36 32,36 C41.584,36 49.479,43.49 49.976,53.052 C50.003,53.586 50.445,54 50.974,54 C50.992,54 51.009,54 51.027,53.999 C51.579,53.97 52.003,53.5 51.974,52.948 C51.512,44.045 45.269,36.778 36.995,34.651 C39.981,32.919 42,29.694 42,26 L42,26 Z M32,34 C27.589,34 24,30.411 24,26 C24,21.589 27.589,18 32,18 C36.411,18 40,21.589 40,26 C40,30.411 36.411,34 32,34 L32,34 Z"
      id="Shape"
    ></path>
    <path
      d="M19,28 C19.552,28 20,27.553 20,27 C20,26.447 19.552,26 19,26 C15.14,26 12,22.859 12,19 C12,15.141 15.14,12 19,12 C21.33,12 23.5,13.154 24.805,15.087 C25.114,15.544 25.736,15.665 26.193,15.357 C26.651,15.047 26.771,14.426 26.462,13.968 C24.785,11.483 21.995,10 19,10 C14.038,10 10,14.037 10,19 C10,22.246 11.733,25.088 14.317,26.671 C7.216,28.711 2,35.252 2,43 C2,43.553 2.448,44 3,44 C3.552,44 4,43.553 4,43 C4,34.729 10.729,28 19,28 L19,28 Z"
      id="Shape"
    ></path>
    <path
      d="M49.683,26.671 C52.267,25.088 54,22.246 54,19 C54,14.037 49.962,10 45,10 C41.952,10 39.134,11.525 37.462,14.079 C37.16,14.542 37.289,15.161 37.752,15.464 C38.214,15.766 38.833,15.637 39.136,15.175 C40.436,13.187 42.629,12 45,12 C48.86,12 52,15.141 52,19 C52,22.859 48.86,26 45,26 C44.448,26 44,26.447 44,27 C44,27.553 44.448,28 45,28 C53.271,28 60,34.729 60,43 C60,43.553 60.448,44 61,44 C61.552,44 62,43.553 62,43 C62,35.252 56.784,28.711 49.683,26.671 L49.683,26.671 Z"
      id="Shape"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "64px",
    },
    height: {
      type: String,
      default: "64px",
    },
  },
};
</script>

<style scoped>
.techno-icon {
  fill: #009f4d !important;
}
</style>