<template>
    <v-list-item :to="url" :class="{'v-list-item--active': active}" v-cloak>
        <v-list-item-icon>
            <slot :width="'25px'" :height="'25px'"/>
        </v-list-item-icon>
        <v-list-item-title>{{title}}</v-list-item-title>
      </v-list-item>
</template>
<script>

export default {
    props: {
        url: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        }
    }
}
</script>
