<template>
  <div style="height:100%;background:#F1F1F1;">
    <v-app-bar absolute style="background:white;">
      <router-link to="/"><img :src="'/assets/svg/'+ $t('general.company_name') + '.svg'" id="login-logo" /></router-link>

      <v-toolbar-title></v-toolbar-title>
      <h1 class="hidden-sm-and-down" id="h1-title">{{$t("general.app_name")}}</h1>
      <v-spacer></v-spacer>

      <v-menu v-model="showAbout" :close-on-content-click="false" :nudge-width="0">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-help</v-icon>
          </v-btn>
        </template>

        <v-card style="padding:10px; border-radius:8px;">
          <h2>{{ $t("login.about") }}</h2>
          <v-divider></v-divider>
          <p position="stacked" style="margin-bottom: 0px;">{{ $t("login.by_phone") }}</p>
          <a class="text-techno" href="tel:418-755-0294">418-755-0294</a>

          <p style="margin-top:10px;margin-bottom:0px;">{{ $t("login.by_email") }}</p>
          <a
           class="text-techno"
           :href="'mailto:' + $t('general.supportEmail')"
          >{{ $t("general.supportEmail") }}</a>
        </v-card>
      </v-menu>

      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </template>

        <v-list style="min-width:250px;">
          <LanguageToggler />
        </v-list>
      </v-menu>
    </v-app-bar>

    <div id="login-content">
 
        <v-card id="login-card" elevation="2">
          <form @submit.prevent="onSubmit" style="text-align:center;">
            <fieldset style="border:none;padding: 100px 25px 0px 25px;">
               <v-card-title id="login-title">{{
                  $t("login.forgotpwd")
                }}</v-card-title>
            
                <v-text-field
                  :label="$t('login.username')"
                  name="username"
                  v-model="username"
                  v-validate="'required'"
                  style="margin: 25px 0px;"
                  type="text"
                ></v-text-field>
              
               <div>
                  <v-btn id="btn-submit" class="btn-techno" type="submit" elevation="2" rounded :disabled="!username">
                    <span v-if="loading">
                      <v-progress-circular
                        :size="25"
                        style="color:#fff;"
                        indeterminate
                      ></v-progress-circular>
                    </span>
                    <span v-else>
                      {{ $t("email.submit") }}
                    </span>
                  </v-btn>
                </div>
                <br/>
                <span v-if="message" style="color:#ff4340;">{{ message }}</span>
            </fieldset>
          </form>
        </v-card>

        <v-snackbar v-model="showConfirmation" timeout="-1">
        {{ $t("email.check_email") }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="showConfirmation = false"
          >
            {{ $t("general.close") }}
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import LanguageToggler from '@/components/menus/LanguageToggler';

export default {
  name: "ForgotPassword",
  components: {
    LanguageToggler
  },
  data() {
    return {
      showAbout: false,
      username: '',
      loading: false,
      message: '',
      showConfirmation: false
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  mounted() {
    // Aviser l'utilisateur du CapsLock
    let $this = this;
    document.querySelector('input[name="password"]').addEventListener('keyup',
      function (e) {
          if (typeof e.getModifierState === "function"){
              $this.capsLockIsOn = (e.getModifierState('CapsLock')?true:false);
          }
      });
  },  
  methods: {
    onSubmit: function() {
      this.message = '';
      this.loading = false;

      let emailData = { email: this.username, locale: this.$i18n.locale };

      this.$store.dispatch("auth/verifyEmail", emailData).then(
        () => {
          this.message = '';
          this.loading = false;

          this.showConfirmation = true;
          setTimeout(() => {
            this.$router.push("/login");
          }, 1000);
        },
        (error) => {
          this.loading = false;

          if (error.response && error.response.data) {
            if (error.response.data.message) {
              this.message = error.response.data.message;
            }
            else {
              this.message = error.response.data;
            }
          }
          else if (error.message) {
            this.message = error.message;
          }
          else {
            this.message = error.toString();
          }
        }
      );
      
    }
  }
};
</script>

<style scoped>

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
a {
  text-decoration: none;
}
</style>